import { useState } from "react";
import '../css/Loading.css';
import InformationTable from "../conponents/InformationTable";
import GenericTemplate from "../templates/GenericTemplate";
import { Button } from "@material-ui/core";

const InformationPage = (props) =>{
  const {
    setLogin_user_id,
    setKintai_bscs,
    Login_user_info,
    setDisplay_user_info,
    InformationFromEnterprise,
    InformationFromSystem,
    Users,
  } = props;

  
  const [isViewClosedEnterprise, setIsViewClosedEnterprise] = useState(false);
  const [isViewClosedSystem, setIsViewClosedSystem] = useState(false);

    return (
      <GenericTemplate title="お知らせ一覧"
          setLogin_user_id={setLogin_user_id}
          setKintai_bscs={setKintai_bscs}
          Login_user_info={Login_user_info}
          setDisplay_user_info={setDisplay_user_info}
      >
      {!(InformationFromEnterprise && InformationFromSystem)?<div class="loader">Loading...</div>:
        <div>
          <div class="information_content">
            <h2>お知らせ</h2>
            {Login_user_info.authority===10?"":
              <Button onClick={()=>setIsViewClosedEnterprise(!isViewClosedEnterprise)}>{isViewClosedEnterprise?"公開中のみ":"全て表示"}</Button>
            }
            <InformationTable
              Information_list={InformationFromEnterprise}
              infotype="information_from_enterprise"
              isViewClosed={isViewClosedEnterprise}
              Users={Users}
              edit_flg={Login_user_info.authority!==10}
            >
            </InformationTable>
          </div>
          <div class="information_content">
            <h2>まめれこからのお知らせ</h2>
            {Login_user_info.authority!==90?"":
              <Button onClick={()=>setIsViewClosedSystem(!isViewClosedSystem)}>{isViewClosedSystem?"公開中のみ":"全て表示"}</Button>
            }
            <InformationTable
              Information_list={InformationFromSystem}
              infotype="information_from_system"
              isViewClosed={isViewClosedSystem}
              Users={Users}
              edit_flg={Login_user_info.authority===90}
            >
            </InformationTable>
          </div>
        </div>
      }
      </GenericTemplate>
    );
}

export default InformationPage;