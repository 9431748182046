import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/';
import { FormControlLabel, List, ListItem, ListItemText, Switch } from '@material-ui/core';
import '../css/Loading.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

// name: DepMstMemberList
// summary: 部署マスタ編集画面_メンバーリストコンポーネント
// prop1: Users ユーザ情報
// prop2: setUsers ユーザ情報のセッター
// prop3: Department_mst 選択中の部署情報
const DepMstMemberList = (props) => {

  // 01_props
  const {Users, setUser, Department_mst, handleEditUserButton, targetUserId, setTargetUserId} = props;

  // 02_useState
  const classes = useStyles();
  const [isGetRetireMembers, setIsGetRetireMembers] = useState(false);
  
  // 03_独自関数(eventHandlerなど)
  var userList = [];
  if(isGetRetireMembers){
    userList = Users.concat();;
  }else{
    userList = Users.filter((p)=>new Date(p.retire_date?p.retire_date:"2200/12/31").getTime() > new Date().getTime())
  }


  const handleClickUser = (user) => {
    setUser(user);
    setTargetUserId(user.user_id);
  }

  return (
    <div className={classes.root}>
    <FormControlLabel
        control={<Switch checked={isGetRetireMembers} onChange={()=>setIsGetRetireMembers(!isGetRetireMembers)} name="date" />}
        label="退職者も表示"
    />
      <List  component="nav" aria-label="department_users">
        {!Users ? <div class="loader">Loading...</div> :  
        userList.map((user, index)=>(
          user.department_cd === Department_mst.department_cd &&
              <ListItem 
                button 
                onClick={()=>handleClickUser(user)}
                onDoubleClick={()=>handleEditUserButton()} 
                className={user.log_del_flg ? "del" : "use"}
                style={{borderLeft:user.user_id === targetUserId?'#8dbb5a 5px solid':''}}
              >
                <ListItemText primary={user.user_id + "　:　" + user.first_name + " " + user.second_name} />
              </ListItem>
        ))}
      </List>
    </div>
  );
}
export default DepMstMemberList;