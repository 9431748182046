import { Button } from '@material-ui/core';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import * as commonFnc from '../common/commonFnc';

import Box from '@mui/material/Box';
import usePrompt from '../common/usePrompt';

const ImportShiftEditer = (props) => {
  const {
    Login_user_info,
    Shift_msts,
    Users,
    setLogin_user_info,
  } = props;


  //CSVから読み込んだデータ
  const [csvData, setCsvData] = useState(null)
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [summary, setSummary] = useState([]);

  useEffect(()=>{
    const tmp_data = csvData?.data.map((p)=>{ return p});
    var tmp_column = [];
    var tmp_rows = [];

    if(tmp_data?.length > 0){
      const header = csvData.data[0];
      tmp_data.shift();

      tmp_column.push({field: "index", headerName: "index", width: 50, editable: false })
      // 1行目をカラム名に設定
      header.map((item)=>(
        tmp_column.push({field: item, headerName: item, width: 100, editable: false })
      ))
      tmp_column.push({field: "user_name", headerName: "user_name", width: 200, editable: false })
      tmp_column.push({field: "shift_name", headerName: "shift_name", width: 200, editable: false })
      tmp_column.push({field: "harf_holiday_shift_name", headerName: "harf_holiday_shift_name", width: 200, editable: false })
      tmp_column.push({field: "Error", headerName: "Error", width: 500, editable: false })

      // 2行目移行をデータ部として読み込むためにJSON形式に変換
      tmp_rows = csvToJSON(header, tmp_data, Shift_msts, Users);
      
      setColumns(tmp_column);
      setRows(tmp_rows);

    }
  },[csvData]);

  useEffect(async ()=>{

    // 登録済みのシフトを検索
    var confirmShifts = [];
    const queryJson = rows.map((row)=>{
      return {
        date: new Date(row.date),
        user_id: row.user_id,
      }
    });

    if (queryJson.length > 0) {
      const res = await fetch('/api/select/individual_kintai_bscs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ csvRows: queryJson }),
      });
    
      confirmShifts = await res.json();
    }

    // CSVデータを人ごとに集計
    const tmp_summary = rows.reduce((result, current) => {
      const element = result.find((p) => p.user_id === current.user_id);
      if (element) {
        element.public_holiday_time += sumHolidayTime(Shift_msts, "public_holiday_time", current);
        element.paid_holiday_time += sumHolidayTime(Shift_msts, "paid_holiday_time", current);
      } else {
        result.push({
          user_id: current.user_id,
          public_holiday_time: sumHolidayTime(Shift_msts, "public_holiday_time", current),
          paid_holiday_time: sumHolidayTime(Shift_msts, "paid_holiday_time", current),
        });
      }
      return result;
    }, []);

    // 有給残数の確認
    tmp_summary.map((userSummary)=>{
      const user = Users.find(p=>p.user_id === userSummary.user_id);
      if(user){
        const sum_delete_public_holiday_time = confirmShifts.filter(p=>p.user_id === user.user_id).reduce((result, current)=>result + (current.public_holiday_time?current.public_holiday_time:0),0);
        const sum_delete_paid_holiday_time = confirmShifts.filter(p=>p.user_id === user.user_id).reduce((result, current)=>result + (current.paid_holiday_time?current.paid_holiday_time:0),0);

        userSummary.enable_public_holiday_days = user.enable_public_holiday_days; // 今の公休残数
        userSummary.enable_paid_holiday_days = user.enable_paid_holiday_days; // 今の有給残数
        userSummary.after_delete_enable_public_holiday_days = user.enable_public_holiday_days + (sum_delete_public_holiday_time/8); //削除処理後
        userSummary.after_delete_enable_paid_holiday_days = user.enable_paid_holiday_days + (sum_delete_paid_holiday_time/8); //削除処理後
        userSummary.after_insert_enable_public_holiday_days = user.enable_public_holiday_days + (sum_delete_public_holiday_time/8) - (userSummary.public_holiday_time/8); //登録処理後
        userSummary.after_insert_enable_paid_holiday_days = user.enable_paid_holiday_days + (sum_delete_paid_holiday_time/8) - (userSummary.paid_holiday_time/8); //登録処理後
      }
    })

    rows.map((row)=>{
      row["Error"]=regularValidate(row, confirmShifts);
      row["Error"]+=summaryValidate(row, tmp_summary);
    })

    setSummary(tmp_summary);
  },[rows])

  /**
   * 選択したCSVファイルの内容をパース
   * @param {*} e 選択したファイル情報
   * @returns 
   */
  const importShiftCsv = (e) => {
    const files = e.target.files
    if (files.length == 0) return

    //CSVをパース
    Papa.parse(files[0], {
      complete: function(results) {
        setCsvData(results)
      }
    })
  };

  /**
   * CSVデータをJSON形式に変換する。
   * @param {*} header csvデータのヘッダー部分:1行目
   * @param {*} body  csvデータのボディ部分:2行目以降
   * @returns 
   */
  const csvToJSON = (header, body, Shift_msts, Users) => {
    var jsondata = [];
    body.map((row, index)=>{
      var line = {};
      line["index"]=index+1;

      for(var i=0; i<row.length;i++){
        line[header[i]]=row[i];
      }
      
      const user = Users.find(p=>p.user_id === line?.user_id);
      const shift = Shift_msts.find(p=>p.shift_type_cd === line?.shift_type_cd);
      const harf_holiday_shift = Shift_msts.find(p=>p.shift_type_cd === line?.harf_holiday_shift_type_cd);
      const paid_holiday_shift = Shift_msts.find(p=>p.shift_type_cd === line?.paid_holiday_shift_type_cd);
      line["public_holiday_time"]=(shift?shift.public_holiday_time:0) + (harf_holiday_shift?harf_holiday_shift.public_holiday_time:0) + (paid_holiday_shift?paid_holiday_shift.public_holiday_time:0);
      line["paid_holiday_time"]=(shift?shift.paid_holiday_time:0) + (harf_holiday_shift?harf_holiday_shift.paid_holiday_time:0) + (paid_holiday_shift?paid_holiday_shift.paid_holiday_time:0);

      line["user_name"]=user?.first_name + " " + user?.second_name;
      line["shift_name"]=shift?.shift_name;
      line["harf_holiday_shift_name"]=harf_holiday_shift?.shift_name;
      line["first_approval_userid"]=user?.first_approval_userid;
      line["second_approval_userid"]=user?.second_approval_userid;
       //改行のみはスキップ
      if(row.length!==1) {
        jsondata.push(line);
      }
    });
    return jsondata;
  }

  /**
   * 休暇時間を算出する
   * @param {Array} Shift_msts 
   * @param {String} targetName 
   * @param {String} shiftTypeName 
   * @return {number} 
   */
  const sumHolidayTime = ( Shift_msts, targetName, current) =>{
    var sum = 0;
    const shift = Shift_msts.find(p=>p.shift_type_cd === current.shift_type_cd);
    if(shift){
      sum += shift[targetName];
    }
    const harf_holiday_shift = Shift_msts.find(p=>p.shift_type_cd === current.harf_holiday_shift_type_cd);
    if(harf_holiday_shift){
      sum += harf_holiday_shift[targetName];
    }
    const paid_holiday_shift = Shift_msts.find(p=>p.shift_type_cd === current.paid_holiday_shift);
    if(paid_holiday_shift){
      sum += paid_holiday_shift[targetName];
    }
    return sum
  }

  /**
   * 対象の項目がマスタに含まれるか判定する。
   * @param {Array} list 検索範囲のリスト。マスタなど。
   * @param {String} listItemName リストの検索対象項目の名称
   * @param {Object} row 検索対象のRow
   * @param {String} itemName 検索対象項目の名称
   * @returns {boolean}
   */
  const existItem = (list, listItemName, row, itemName)=>{
    if(!row[itemName] || list?.find(p=>p[listItemName] === row[itemName])){
      return true
    }
    return false
  }


  /**
   * 行ごとのバリデーションチェック
   * @param {*} row 
   * @param {Array} confirmShifts 
   * @returns {string} msg
   */
  const regularValidate = (row, confirmShifts)=>{

    var msg = "";

    // 日付の形式チェック
    var date = new Date(row.date)
    if(isNaN(date.getDate())){
      msg += `日付の形式が不正です。`
    }

    // user_id存在チェック
    if(!existItem(Users, row, "user_id")){
      msg += `ユーザが存在しません。`
    }

    // shift_type_cd 存在チェック
    if(!existItem(Shift_msts, "shift_type_cd", row, "shift_type_cd")){
      msg += `シフトが存在しません。`
    }
    // harf_holiday_shift_type_cd 存在チェック
    if(!existItem(Shift_msts, "shift_type_cd", row, "harf_holiday_shift_type_cd")){
      msg += `半日休暇シフトが存在しません。`
    }
    // paid_holiday_shift_type_cd 存在チェック
    if(!existItem(Shift_msts, "shift_type_cd", row, "paid_holiday_shift_type_cd")){
      msg += `時間有給シフトが存在しません。`
    }

    const confirmShift = confirmShifts.find(p=>( commonFnc.formatDate(p.date) === row.date && p.user_id === row.user_id && p.approval_status_cd !== "10"));
    if(confirmShift){
      msg += `登録済みのシフトです。`;
    }

    return msg;

  }

  /**
   * 人ごとのバリデーションチェック
   * @param {*} row 
   * @param {*} tmp_summary 
   * @returns 
   */
  const summaryValidate = (row, tmp_summary) =>{
    var msg = "";

    if(tmp_summary.length===0) return;

    const summaryRow = tmp_summary.find(p=>p.user_id === row.user_id);
    if(summaryRow.after_insert_enable_public_holiday_days < 0){
      msg += `公休日数が足りません。`;
    }
    if(summaryRow.after_insert_enable_paid_holiday_days < 0){
      msg += `有給日数が足りません。`;
    }

    return msg;
  }

  /**
   * CSVデータの登録処理
   * @returns 
   */
  const importCsvData = async () =>{
    try {

      const message = `未申請の勤務がある場合は削除されます。よろしいですか？\n
                      ※ブラウザを更新、×ボタンで閉じると処理が中断します。\n
                      (推定処理時間は${rows.length}秒です。)`
                      ;
      var result = window.confirm(message);
      if( !result ) return;

      if(!rows || rows.length===0){
        alert('登録対象がありません。CSVを読み込んでください。');
        return
      } 
      
      for(var i=0; i < rows.length; i++){
        if(rows[i]?.Error?.length > 0){
          alert('エラーレコードがあります。登録出来ません。');
          return
        }
      }

      // 進捗管理を開始
      let completed = 0;
      progressInitial(rows.length);


      for (const row of rows) {

        const summaryRow = summary.find(p=>(p.user_id === row.user_id));
        // 既存の勤務の削除
        var url = "/api/deleteOne/request_kintai_bsc"
        var bodyData = {
            user_id:row.user_id,
            date: commonFnc.formatDate(row.date),
            enable_public_holiday_days:summaryRow.after_delete_enable_public_holiday_days,
            enable_paid_holiday_days:summaryRow.after_delete_enable_paid_holiday_days,
        }
        const requestOptions ={
            method: 'POST',
            headers:{'Accept': 'application/json','Content-Type': 'application/json'},
            body: JSON.stringify(bodyData)
        };
        await fetch(url,requestOptions);
        // commonFnc.insertUserLog("INFO", Login_user_info.user_id, __filename, "CSVインポート画面", "勤怠削除処理", "削除処理", "", null, bodyData);

        // 勤務の登録
        var url = "/api/insert/kintai_bsc"
        var bodyData = {
            user_id : row.user_id,
            date : commonFnc.formatDate(row.date),
            shift_type_cd :row.shift_type_cd,
            harf_holiday_shift_type_cd : row.harf_holiday_shift_type_cd,
            paid_holiday_shift_type_cd : row.paid_holiday_shift_type_cd,
            public_holiday_time : row.public_holiday_time,
            paid_holiday_time : row.paid_holiday_time,
            enable_public_holiday_days : summaryRow.after_insert_enable_public_holiday_days,
            enable_paid_holiday_days : summaryRow.after_insert_enable_paid_holiday_days,
            first_approval_userid:row.first_approval_userid,
            second_approval_userid:row.second_approval_userid,
        }
        const requestOptionsInsert ={
            method: 'POST',
            headers:{'Accept': 'application/json','Content-Type': 'application/json'},
            body: JSON.stringify(bodyData)
        };

        await fetch(url,requestOptionsInsert);
        // commonFnc.insertUserLog("INFO", Login_user_info.user_id, __filename, "CSVインポート画面", "勤怠登録処理", "登録処理", "", null, bodyData);

        progressCountUp(completed+=1, row);
      }


      // ユーザ情報の更新
      const queryGetLoginUser = new URLSearchParams({user_id: Login_user_info.user_id});
      const res = await fetch(`/api/select/login_user?${queryGetLoginUser}`)
      const data = await res.json();
      setLogin_user_info(data);
      
      alert('勤怠の登録が完了しました。');
      progressClear();

      
    } catch (error) {
        alert("予期せぬエラーが発生しました。\n処理を中断します。\n通信を確認し再実行してください。")
    }
  }
  
    
  // 進捗管理用
  const [startProgress, setStartProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressUserName, setProgressUserName] = useState("");
  const [progressDate, setProgressDate] = useState("");
  const [allNum, setAllNum] = useState(0);
  const progressInitial = (allNum) =>{
      setStartProgress(true);
      setProgress(0);
      setProgressUserName("");
      setProgressDate("");
      setAllNum(allNum);
  }
  const progressCountUp = (completedNum, row) =>{
      setProgress(completedNum);
      // setProgressUserName(row?.user_name)
      // setProgressDate(commonFnc.formatDate(row?.date));
  }
  const progressClear = () =>{
      setStartProgress(false);
      setProgress(0);
      setProgressUserName("");
      setProgressDate("");
      setAllNum(0)
  }

  // 処理中の遷移確認(rooting、ブラウザバック)
  usePrompt("本当にこのページを離れますか？", progress>0);
  
  // ブラウザ(×ボタン、ブラウザリロード)
  useEffect(() => {
      const handleBeforeUnload = (event) => {
          event.preventDefault(); // これが必要
          return ;
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      // クリーンアップ関数でイベントリスナーを削除
      return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
      };
  }, []);

  return (
    <div className={startProgress?"loading-wrapper":false}>
        {startProgress?
        <div class="loading-center">
            <div class="loader">Loading...</div>
            <div>処理済み<br/><br/>
              {/* {`${progressUserName}/${progressDate}`}<br/><br/> */}
              {`${progress}/${allNum}`}
            </div>
        </div>:<></>
        }
        <Button variant="outlined" onClick={()=>importCsvData()} disabled={startProgress||rows?.length===0}>登録</Button>
        <input type="file" onChange={importShiftCsv}/>
        
        <Box
          sx={{
            '& .rowRed': {
              backgroundColor: 'Gold',
              color: 'Red',
            },
          }}
        >
          <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              autoHeight={true}
              disableSelectionOnClick
              getRowId={(rows) => rows.index}
              components={{
                Toolbar: GridToolbar
              }}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              getRowClassName={(params) => {
                if(params.row.Error) return "rowRed";
                return '';
              }}
          />
        </Box>
    </div>
  );
}
export default ImportShiftEditer;