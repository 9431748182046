import * as commonFnc from '../common/commonFnc';





/**
 * 対象者の承認順序を取得する。
 * @param {Array} Department_msts 部署情報一覧
 * @param {user} targetUserData 勤怠のユーザ情報
 * @param {String} login_user_id ログインユーザID
 * @returns なし
 */
export function getApprovalOder(Department_msts, targetUserData, login_user_id){
    
    let targetDepartment = Department_msts.find(p=>p.department_cd === targetUserData.department_cd);

    if( targetUserData.second_approval_userid === login_user_id ){
        // ユーザに承認者が設定されている。
        return "second";
    }else if( !targetUserData.second_approval_userid && targetDepartment?.second_approval_userid === login_user_id ){
        // ユーザの承認者が設定されていないかつ、部署の承認者が設定されている。
        return "second";
    }
    return "first";
}

// summary: 次回承認者を取得する。
// param1 : User User 対象のユーザ情報
// param1 : Department_msts Department_msts 部署マスタ一覧
// param1 : String approval_status_cd 現在の承認状況
// return : String next_approval_userid 次回承認者id
export function getNextApprovalUser(User, Department_msts, approval_status_cd) {

    if(!User || !Department_msts || !approval_status_cd){
        return ;
    }

    const myDep = Department_msts.find( depmst => depmst.department_cd === User.department_cd);
    // 差戻しの場合は現在の承認ステータスに関わらずbackが渡される。
    
    
    if(approval_status_cd === "10" || approval_status_cd === "80"){
        if(User?.first_approval_userid && User.first_approval_userid !== ""){
            return User.first_approval_userid;
        }else if(myDep?.first_approval_userid && myDep.first_approval_userid !== ""){
            return myDep.first_approval_userid;
        }
    }else if(approval_status_cd === "20"){
        if(User?.second_approval_userid && User.second_approval_userid !== ""){
            return User.second_approval_userid;
        }else if(myDep?.second_approval_userid && myDep.second_approval_userid !== ""){
            return myDep.second_approval_userid;
        }
    }else{
        return "";
    }

    // if(approval_status_cd === "10" || approval_status_cd === "80"){

    //     if(User.first_approval_userid || User.first_approval_userid === undefined || User.first_approval_userid === ""){
    //         return myDep.first_approval_userid;
    //     }else{
    //         return User.first_approval_userid;
    //     }

    // }else if(approval_status_cd === "20"){

    //     if(User.second_approval_userid || User.second_approval_userid === undefined || User.second_approval_userid === ""){
    //         return myDep.second_approval_userid;
    //     }else{
    //         return User.second_approval_userid;
    //     }
    // }

    return ;
}

// summary: 前回承認者を取得する。
// param1 : User User 対象のユーザ情報
// param1 : Department_msts Department_msts 部署マスタ一覧
// param1 : String approval_status_cd 現在の承認状況
// return : String next_approval_userid 前回承認者id
export async function getBeforeApprovalUser(User, Department_msts, approval_status_cd) {

    if(!User || !Department_msts || !approval_status_cd){
        return ;
    }

    const myDep = Department_msts.find( depmst => depmst.department_cd === User.department_cd);

    if(approval_status_cd === "40"){

        if(User.second_approval_userid || User.second_approval_userid === undefined || User.second_approval_userid === ""){
            return myDep.second_approval_userid;
        }else{
            return User.second_approval_userid;
        }

    }else if(approval_status_cd === "30"){

        if(User.first_approval_userid || User.first_approval_userid === undefined || User.first_approval_userid === ""){
            return myDep.first_approval_userid;
        }else{
            return User.first_approval_userid;
        }
    }else{

        return ;
    }
    
}

// summary: 次回承認CDを取得する。
// param1 : String nowApprovalcd 現在の承認CD
// return : String next_approval_userid 次回承認CD
/**
 * 次回承認CDを取得する。
 * @param {String} nowApprovalcd 現在の承認CD
 * @param {String} approvalOrder ログインユーザの承認順序
 * @returns {String} next_approval_cd 次回承認CD
 */
export function getNextApprovalCd(nowApprovalcd, approvalOrder) {

    var nextcd = "0";

    if(approvalOrder === "second"){

        nextcd = "90";

    }else{

        if(nowApprovalcd === "10" || nowApprovalcd === "80" || nowApprovalcd === null || nowApprovalcd === undefined){
            nextcd = "20";
        }else if(nowApprovalcd === "20"){
            nextcd = "30";
        }else if(nowApprovalcd === "30"){
            nextcd = "90";
        }else{
            nextcd = "10";
        }

    }


    return nextcd;
}

// summary: 前回承認CDを取得する。
// param1 : String nowApprovalcd 現在の承認CD
// return : String next_approval_userid 前回承認CD
export function getBeforeApprovalCd (nowApprovalcd) {

    var nextcd = "0";

    if(nowApprovalcd === "30"){
        nextcd = "20";
    }else if(nowApprovalcd === "20"){
        nextcd = "10";
    }else if(nowApprovalcd === "90"){
        nextcd = "30";
    }else{
        nextcd = "";
    }

    return nextcd;
}


/**
 * 承認申請をする。
 * @param {Kintai_bsc} target_kintai_bsc 対象の勤怠情報
 * @param {Array} Department_msts 部署情報一覧
 * @param {Obj} setKintai_bscs 勤怠情報のセッター
 */
export async function requestApproval (target_kintai_bsc, Department_msts, setKintai_bscs) {

    // ユーザ情報を取得し、次回承認者と次回承認CDを更新する。
    const query = new URLSearchParams({user_id: target_kintai_bsc.user_id});

    let res = await fetch(`/api/select/user?${query}`);
    let targetUserData = await res.json();
    let approvalOrder = getApprovalOder(Department_msts, targetUserData, target_kintai_bsc.user_id);
    let nextid = getNextApprovalUser(targetUserData, Department_msts, target_kintai_bsc.approval_status_cd);
    let nextcd = getNextApprovalCd(target_kintai_bsc.approval_status_cd, approvalOrder);

    updateKintaiApprovalInfo(
        setKintai_bscs,
        target_kintai_bsc.user_id,
        target_kintai_bsc.date,
        nextid,
        nextcd
    );

}


/**
 * 取消申請をする。
 * @param {Kintai_bsc} target_kintai_bsc 対象の勤怠情報
 * @param {Array} Department_msts 
 * @param {Obj} setKintai_bscs 勤怠情報のセッター
 */
export async function requestCancel(target_kintai_bsc, Department_msts, setKintai_bscs) {

    // ユーザ情報を取得し、次回承認者と次回承認CDを更新する。
    const query = new URLSearchParams({user_id: target_kintai_bsc.user_id});
    let res = await fetch(`/api/select/user?${query}`);
    let targetUserData = await res.json();
    let beforeid = getBeforeApprovalUser(targetUserData, Department_msts, target_kintai_bsc.approval_status_cd);
    let beforecd = getBeforeApprovalCd(target_kintai_bsc.approval_status_cd);

    updateKintaiApprovalInfo(
        setKintai_bscs,
        target_kintai_bsc.user_id,
        target_kintai_bsc.date,
        beforeid,
        beforecd
    );

}

export async function updateKintaiApprovalInfo (setKintai_bscs,target_user_id, target_date, target_approval_userid, target_approvalcd){

    // 勤怠情報の承認CDと次回承認者を更新する。
    const bodyParam = new URLSearchParams({
        user_id:target_user_id, 
        date:commonFnc.formatDate(target_date), 
        next_approval_userid: target_approval_userid, 
        approval_status_cd: target_approvalcd,
        last_approval_userid: target_user_id, 
    });

    // 更新
    const params = {method : "POST", body : bodyParam};
    await fetch(`/api/insert/kintai_bsc2`, params);

    // データ再取得
    const queryKintai = new URLSearchParams({login_user_id: target_user_id});
    const res = await fetch(`/api/select/kintai_bsc?${queryKintai}`);
    const data = await res.json();
    setKintai_bscs(data);

    // const params = {method : "POST", body : bodyParam};
    // fetch(`/api/insert/kintai_bsc2`, params)
    // .then(()=>{
    //     // 画面表示を更新する。
    //     const queryKintai = new URLSearchParams({login_user_id: target_user_id});
    //     fetch(`/api/select/kintai_bsc?${queryKintai}`)
    //     .then((res) => res.json())
    //     .then((data) => setKintai_bscs(data));
    // });
}