import React, { useContext, useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, makeStyles, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import { GlobalContext } from '../globalState';
import * as commonFnc from '../common/commonFnc';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles({
  modalTextField:{
    margin: '5px',
    width: '100%'
  }
});


/**
 * 休みシフト登録用のモーダル
 * @param {*} Login_user_info ログインユーザ情報
 * @param {*} pwModalopen モーダル表示のstate
 * @param {*} setPwModalopen モーダル表示のstateのセッター
 * @returns 
 */
const KintaiHolidayShiftEntryModal = (props) => {

  // 01_props
    const {  
      setLogin_user_info,
      display_user_info,
      setDisplay_user_info, 
      isModalOpen,
      setIsModalOpen,
      Shift_msts,
      Kintai_bsc,  
      setKintai_bsc,
      setKintai_bscs,
      harfWorkFlg,
  } = props;

  // 02_useState
  const classes = useStyles();
  const shift_type_cd_hash = useContext(GlobalContext).shift_type_cd;
  const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;
  const [radioShift, setRadioShift] = useState()
  const [paidholidayReason, setPaidHolidayReason] = useState("")

  // 03_独自関数(eventHandlerなど)
  const handleClose = () => setIsModalOpen(false);
  
  const handleInputChage = (e, setObj, obj)=>{
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setObj({ ...obj, [name]: value });
    
  }

  // シフト登録申請
  const handleSubmit = async ()=>{
    if(!radioShift){
      alert("シフトを選択してください。");
      return;
    }
    if((radioShift === shift_type_cd_hash["PAID_HOLIDAY_CD"] || radioShift === shift_type_cd_hash["HARF_PAID_HOLIDAY_CD"]) && !paidholidayReason){
      alert("有給申請理由を入力してください。");
      return;
    }


    const fmt_shiftDate = commonFnc.formatDate(Kintai_bsc.date);
    const tmp_user_id = display_user_info.user_id;

    const shift = Shift_msts.find(p=>p.shift_type_cd === radioShift);
    var after_enable_paid_holiday_days = display_user_info.enable_paid_holiday_days;
    var after_enable_public_holiday_days =  display_user_info.enable_public_holiday_days;

    after_enable_public_holiday_days -= shift&&shift.public_holiday_time?shift.public_holiday_time/8:0;
    after_enable_paid_holiday_days -= shift&&shift.paid_holiday_time?shift.paid_holiday_time/8:0;

    if(after_enable_public_holiday_days < 0 || after_enable_paid_holiday_days < 0){
      alert("残数が足りません。");
      return;
    }


    // 勤怠情報登録
    var urlShift = "/api/insert/holidayShiftEntry"
    var bodyDataShift = {
        user_id:tmp_user_id,
        date:fmt_shiftDate,
        shift_type_cd:harfWorkFlg ? Kintai_bsc.shift_type_cd : radioShift,
        harf_holiday_shift_type_cd:harfWorkFlg ? radioShift : null,
        enable_paid_holiday_days:after_enable_paid_holiday_days,
        enable_public_holiday_days:after_enable_public_holiday_days,
        request_paid_holiday_reason:paidholidayReason,
        public_holiday_time:shift.public_holiday_time,
        paid_holiday_time:shift.paid_holiday_time,
        first_approval_userid:display_user_info.first_approval_userid,
        second_approval_userid:display_user_info.second_approval_userid,
    }

    const requestOptionsShift ={
        method: 'POST',
        headers:{'Accept': 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify(bodyDataShift)
    };

    await fetch(urlShift,requestOptionsShift);
    commonFnc.insertUserLog("INFO", tmp_user_id, __filename, "休みシフト登録モーダル", "シフト情報登録", "追加処理", "", null, bodyDataShift);


    //ユーザ情更新
    const queryGetLoginUser = new URLSearchParams({user_id: tmp_user_id});
    fetch(`/api/select/login_user?${queryGetLoginUser}`)
      .then((res)=> res.json())
      .then((data)=>{setLogin_user_info(data); return data;})
      .then((data)=>setDisplay_user_info(data))
    

    // 勤怠再取得
    const query = new URLSearchParams({login_user_id: tmp_user_id});
    fetch(`/api/select/kintai_bsc?${query}`)
      .then((res) => res.json())
      .then((data) => setKintai_bscs(data));


    setRadioShift()
    setPaidHolidayReason("")
    handleClose();
  }

  return (
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            休みシフト登録
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormControl>
              {/* <FormLabel id="radio-buttons-group-label">シフト</FormLabel> */}
              <RadioGroup
                row
                aria-labelledby="radio-buttons-group-label"
                name="shift_type_cd"
                value={radioShift}
                onChange={e => setRadioShift(e.target.value)}
              >
                {harfWorkFlg ? 
                  <div>
                    <FormControlLabel value={shift_type_cd_hash["HARF_PUBLIC_HOLIDAY_CD"]} control={<Radio />} label="半日公休" />
                    <FormControlLabel value={shift_type_cd_hash["HARF_PAID_HOLIDAY_CD"]} control={<Radio />} label="半日有給" />
                  </div>
                :
                  <div>
                    <FormControlLabel value={shift_type_cd_hash["PUBLIC_HOLIDAY_CD"]} control={<Radio />} label="公休" />
                    <FormControlLabel value={shift_type_cd_hash["PAID_HOLIDAY_CD"]} control={<Radio />} label="有給" />
                  </div>          
                }
                
              </RadioGroup>
            </FormControl>
            <TextField 
                label="申請理由" 
                variant="outlined" 
                name="request_paid_holiday_reason" 
                onChange={e => setPaidHolidayReason(e.target.value)} value={paidholidayReason}
                disabled={!(radioShift === shift_type_cd_hash["PAID_HOLIDAY_CD"] || radioShift === shift_type_cd_hash["HARF_PAID_HOLIDAY_CD"])}
                multiline
                minRows="3"
                style = {{width: "100%"}}
            />
          </Typography>
          <Button variant='contained' color='primary' type='submit' className={classes.modalTextField} onClick={() => handleClose()}>キャンセル</Button>
          <Button variant='contained' color='primary' type='submit' className={classes.modalTextField} onClick={() => handleSubmit()}>登録</Button>
        </Box>
      </Modal>
  );
}
export default KintaiHolidayShiftEntryModal;