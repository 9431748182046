import RequestPublicHolidayMstForm from "../conponents/RequestPublicHolidayMstForm";
import GenericTemplate from "../templates/GenericTemplate";

const Mst_publicHolidayMstPage = (props) =>{
    const {setLogin_user_id ,setKintai_bscs, Login_user_info, setDisplay_user_info} = props;


    return (
        <GenericTemplate title="公休マスタ"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        <div className="mstPage_wrapper">
            <div className="mstPage_content">
                <RequestPublicHolidayMstForm 
                    Login_user_info={Login_user_info}
                />
            </div>
        </div>
        </GenericTemplate>
    );
}

export default Mst_publicHolidayMstPage;