import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button"; 
import { useEffect, useState } from 'react';
import { FormControlLabel, makeStyles, MenuItem, Switch } from "@material-ui/core";
import * as commonFnc from '../common/commonFnc';
import CommonIdNameSeqDialog from "../common/CommonIdNameSeqDialog";
import CommonMsgDialog from "../common/CommonMsgDialog";
import CommonDialog from "../common/CommonDialog";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

const useStyles = makeStyles((theme)=>({
    root:{
        '& .MuiTextField-root':{
            margin: theme.spacing(1),
            width: 200,
        }
    }
}))

const RequestPublicHolidayMstForm = (props) =>{
    const {Login_user_info} = props;
    const classes = useStyles();

    const [Public_holiday_msts, setPublic_holiday_msts] = useState([]);
    const [public_holiday_type_msts, setPublic_holiday_type_msts] = useState([]);
    const [targetYear, setTargetYear] = useState(new Date().getFullYear());
    const [year_list, setYear_list] = useState([]);

    const [idNameInsertDlgOpen, setIdNameInsertDlgOpen] = useState(false);
    const [idNameUpdateDlgOpen, setIdNameupdateDlgOpen] = useState(false);
    const [existIdDlgisOpen, setExistIdDlgisOpen] = useState(false);
    const [confirmDelDlgisOpen, setConfirmDelDlgisOpen] = useState(false);
    const [delDlgMsg, setDelDlgMsg] = useState("");
    
    const [isViewLogDel, setIsViewLogDel] = useState(false);

    const [targeTypeMst, setTargeTypeMst] = useState({
        public_holiday_type_cd:"",
        type_name:"",
        seq:0,
        log_del_flg:false,
    });

    // 新規作成ボタンクリック　→　モーダル
    const handleAddButtonClick =()=>{
        setTargeTypeMst({
            public_holiday_type_cd:"",
            type_name:"",
            seq:0,
            log_del_flg:false,
        })
        setIdNameInsertDlgOpen(true);
    }

    // 更新ボタンクリック　→　モーダル
    const handleChangeNameButtonClick =(public_holiday_type_mst)=>{
        setTargeTypeMst({
            public_holiday_type_cd:public_holiday_type_mst.public_holiday_type_cd,
            type_name:public_holiday_type_mst.type_name,
            seq:public_holiday_type_mst.seq,
            log_del_flg:false,
        })
        setIdNameupdateDlgOpen(true);
    }

    // 削除ボタンクリック　→　モーダル
    const handleDeleteButtonClick =(public_holiday_type_mst)=>{
        setTargeTypeMst({
            public_holiday_type_cd:public_holiday_type_mst.public_holiday_type_cd,
            type_name:public_holiday_type_mst.type_name,
            log_del_flg:!public_holiday_type_mst.log_del_flg,
        })

        if(public_holiday_type_mst.log_del_flg){
            setDelDlgMsg(public_holiday_type_mst.type_name + "は削除済みです。復活させますか?");
        }else{
            setDelDlgMsg(public_holiday_type_mst.type_name + "を削除しますか?");
        }
        setConfirmDelDlgisOpen(true);
    }

    // 追加更新削除ロジック
    const upsertPublicHolidayTypeMst = async () =>{
        var url = "/api/update/public_holiday_type_mst"
        var bodyData = {
            public_holiday_type_cd: targeTypeMst.public_holiday_type_cd,
            type_name:targeTypeMst.type_name,
            seq:targeTypeMst.seq,
            log_del_flg:targeTypeMst.log_del_flg,
        }
        const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData)
        };
        const response = await fetch(url, requestOptions);
        
        const res = await fetch(`/api/select/public_holiday_type_msts`);
        const data = await res.json();
        setPublic_holiday_type_msts(data);

        return response;
    }

    // モーダル →　更新処理
    const updateType = ()=>{
        try{
            const response = upsertPublicHolidayTypeMst();
            commonFnc.insertUserLog("INFO", Login_user_info.user_id, __filename, "公休マスタ画面", "公休タイプマスタ更新", "更新処理", "", null, response);
            setIdNameupdateDlgOpen(false);
        }catch{
            // エラーが発生しました。
            setIdNameupdateDlgOpen(false);
            alert("予期せぬエラーが発生しました。ブラウザを閉じて再度ログインをしてください。")
        }
    }

    // モーダル →　追加処理
    const createNewType = async () =>{
        
        setIdNameInsertDlgOpen(false);
        if(!targeTypeMst.public_holiday_type_cd) return

        const query = new URLSearchParams({public_holiday_type_cd: targeTypeMst.public_holiday_type_cd});
        const res = await fetch(`/api/selectOne/public_holiday_type_msts?${query}`);
        const data = await res.json();

        // 存在チェック
        if(!data){
            // 登録処理をしてダイアログを閉じる
            try{

                const response = upsertPublicHolidayTypeMst();
                commonFnc.insertUserLog("INFO", Login_user_info.user_id, __filename, "公休マスタ画面", "公休タイプマスタ登録", "追加処理", "", null, response);
                setIdNameInsertDlgOpen(false);

            }catch{
                // エラーが発生しました。
                setIdNameInsertDlgOpen(false);
                alert("予期せぬエラーが発生しました。ブラウザを閉じて再度ログインをしてください。")
            }
        }else{
            // IDが取得出来きた時の処理
            // 既に登録されています。
            setIdNameInsertDlgOpen(false);
            setExistIdDlgisOpen(true);
        }
    }

    // モーダル →　削除処理
    const deleteType = ()=>{
        try{
            const response = upsertPublicHolidayTypeMst();
            commonFnc.insertUserLog("INFO", Login_user_info.user_id, __filename, "公休マスタ画面", "公休タイプマスタ更新", "削除処理", "", null, response);
            setConfirmDelDlgisOpen(false);
        }catch{
            // エラーが発生しました。
            setConfirmDelDlgisOpen(false);
            alert("予期せぬエラーが発生しました。ブラウザを閉じて再度ログインをしてください。")
        }
    }

    // カレンダーの作成
    function createTypeCal(targetTypeCd){
        let result = []
        for (let index = 0; index < 12; index++) {
            let mst = {
                public_holiday_type_cd: targetTypeCd,
                year : targetYear ? targetYear : new Date().getFullYear(),
                month : index+1,
                public_holiday_num: 0
            }
            result.push(mst);
        }
        return result;
    }

    // 初期処理
    useEffect(async ()=>{
        try {
            const res = await fetch(`/api/select/public_holiday_type_msts`);
            const data = await res.json();
            setPublic_holiday_type_msts(data);
        } catch (error) {
            console.error(error);
        }

        var tmp_year_list = [];
        for (let index = (new Date().getFullYear() - 2); index < (new Date().getFullYear() + 3); index++) {
            tmp_year_list.push(<MenuItem value={index}>{index}年</MenuItem>);
        }
        setYear_list(tmp_year_list);
    },[])

    // 画面更新
    useEffect(async ()=>{
        const query = new URLSearchParams({year: targetYear});
        const res = await fetch(`/api/select/public_holiday_msts?${query}`);
        const data = await res.json();

        var tmp_arr = [];
        public_holiday_type_msts.forEach((public_holiday_type_mst,index)=>{
            const targetTypeCal = data.filter(p=>p.public_holiday_type_cd === public_holiday_type_mst.public_holiday_type_cd);

            if(!targetTypeCal || targetTypeCal.length === 0){
                const cal = createTypeCal(public_holiday_type_mst.public_holiday_type_cd);
                tmp_arr.push(...cal)
            }else{
                tmp_arr.push(...targetTypeCal)
            }
        })
        setPublic_holiday_msts(tmp_arr);
    },[targetYear, public_holiday_type_msts])
    
    const handleInputChage = (e, mstcd, index) =>{
        const target = e.target;
        const value = target.value;

        Public_holiday_msts.find((p)=>p.public_holiday_type_cd === mstcd && p.month === index+1).public_holiday_num = value;
        const arr = Public_holiday_msts.map((p)=>{ return p})
        setPublic_holiday_msts(arr);
    } 

    return(
        <div className="request-form" noValidate>
            {!Public_holiday_msts ? <div>Loding...</div> : 
                <div>
                    <div>
                        <TextField select label="年度" variant="outlined" name="year" 
                            onChange={e => setTargetYear(e.target.value)} 
                            value={targetYear}
                            style={{"margin":"5px"}}
                            >
                            {year_list}
                        </TextField>
                        <FormControlLabel
                            control={<Switch checked={isViewLogDel} onChange={()=>setIsViewLogDel(!isViewLogDel)}/>}
                            label="削除済みも表示"
                            style={{"margin":"5px"}}
                        />
                    </div>
                    { !Public_holiday_msts || !public_holiday_type_msts ? <div>Loding...</div>  :
                        <form action="/api/update/public_holiday_mst" method="POST" className={classes.root}>
                            <div style={{"display" : "flex"}}>
                                {public_holiday_type_msts
                                    .filter(isViewLogDel ? p=>p : p=>p.log_del_flg===false)
                                    .sort((a,b)=>(a.seq < b.seq ? -1 : 1))
                                    .map((public_holiday_type_mst, type_mst_index)=>(
                                    <div class="cal_container" style={{"backgroundColor": public_holiday_type_mst.log_del_flg ? "gray" : "transparent"}} key={type_mst_index}>
                                        <div class="type_name_container">
                                            <div class="type_name">
                                                {public_holiday_type_mst.type_name}<br/>{"("+public_holiday_type_mst.public_holiday_type_cd+")"}
                                            </div>
                                            <div>
                                                <BorderColorOutlinedIcon onClick={()=>handleChangeNameButtonClick(public_holiday_type_mst)} style={{"cursor":"pointer"}}/>
                                                <DeleteForeverOutlinedIcon onClick={()=>handleDeleteButtonClick(public_holiday_type_mst)} style={{"cursor":"pointer"}}/>
                                            </div>
                                        </div>
                                        {Public_holiday_msts
                                        .filter(p=>p.public_holiday_type_cd === public_holiday_type_mst.public_holiday_type_cd)
                                        .sort((a,b)=>(a.month < b.month ? -1 : 1))
                                        .map((Public_holiday_mst, index)=>(
                                            <div key={index}>
                                                <TextField
                                                    size="small"
                                                    label={Public_holiday_mst.month + "月"}
                                                    variant="outlined" name={"public_holiday_num"+"_"+type_mst_index+"_"+index}
                                                    type="number"
                                                    onChange={(e) => handleInputChage(e, public_holiday_type_mst.public_holiday_type_cd, index)}
                                                    value={Public_holiday_mst.public_holiday_num}
                                                />
                                                <input hidden label="年" name={"year"+"_"+type_mst_index+"_"+index} value={targetYear} readOnly/>
                                                <input hidden label="月" name={"month"+"_"+type_mst_index+"_"+index} value={Public_holiday_mst.month} readOnly/>
                                                <input hidden label="公休タイプCD" name={"public_holiday_type_cd"+"_"+type_mst_index+"_"+index} value={public_holiday_type_mst.public_holiday_type_cd} readOnly/>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                                <div style={{ "display": "table"}}>
                                    <div style={{"display": "table-cell","verticalAlign": "middle"}}>

                                    <Button className="acction_button" variant="outlined" style={{margin:"7px"}} onClick={()=>handleAddButtonClick()}>＋</Button>
                                    </div>
                                </div>
                            </div>
                            <input hidden label="公休タイプCDリスト" name={"public_holiday_type_mst_length"} value={public_holiday_type_msts.length} readOnly/>
                            <Button className="acction_button" variant="outlined" type="submit" style={{margin:"7px"}}>登録</Button>
                        </form>
                    }
                </div>
            }
            <CommonIdNameSeqDialog
                msg={"公休タイプの追加"}
                isOpen={idNameInsertDlgOpen}
                doYes={()=>createNewType()}
                doNo={() => {setIdNameInsertDlgOpen(false)}}
                setObj={setTargeTypeMst}
                Obj={targeTypeMst}
                idColumn={"public_holiday_type_cd"}
                nameColumn={"type_name"}
                seqColumn={"seq"}
                isIdColumnDisabled={false}
                >
            </CommonIdNameSeqDialog>
            <CommonIdNameSeqDialog
                msg={"公休タイプの更新"}
                isOpen={idNameUpdateDlgOpen}
                doYes={()=>updateType()}
                doNo={() => {setIdNameupdateDlgOpen(false)}}
                setObj={setTargeTypeMst}
                Obj={targeTypeMst}
                idColumn={"public_holiday_type_cd"}
                nameColumn={"type_name"}
                seqColumn={"seq"}
                isIdColumnDisabled={true}
                >
            </CommonIdNameSeqDialog>
            <CommonMsgDialog
                msg={"既に登録されています。"}
                isOpen={existIdDlgisOpen}
                doYes={() => {setExistIdDlgisOpen(false)}}
            />
            <CommonDialog

                msg={delDlgMsg}
                isOpen={confirmDelDlgisOpen}
                doYes={deleteType}
                doNo={() => {setConfirmDelDlgisOpen(false)}}

            />
        </div>
    );
}
export default RequestPublicHolidayMstForm;