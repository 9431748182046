import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, makeStyles, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";

import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height:'90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles({
  modalTextField:{
    margin: '5px',
    width: '100%'
  }
});


/**
 * スマホメニュー用のモーダル
 * @param {*} Login_user_info ログインユーザ情報
 * @param {*} menuModalOpen モーダル表示のstate
 * @param {*} setMenuModalopen モーダル表示のstateのセッター
 * @param {*} setPwModalopen PWモーダル表示のstateのセッター
 * @returns 
 */
const SP_MenuModal = (props) => {

  // 01_props
  const{
    Login_user_info, 
    menuModalOpen, 
    setMenuModalOpen,
    setPwModalopen,
  } = props;

  // 03_独自関数(eventHandlerなど)
  const handleClose = () => setMenuModalOpen(false);

  return (
      <Modal
        open={menuModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <List>
            <Link to="/sp_kintaiListPage" >
              <ListItem button onClick={handleClose}>
                <ListItemIcon>
                  <InsertInvitationIcon />
                </ListItemIcon>
                <ListItemText primary="勤務カレンダー" />
              </ListItem>
            </Link>
            <Link to="/sp_kintaiListPage" >
              <ListItem button onClick={setPwModalopen}>
                <ListItemIcon>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText primary="パスワード変更" />
              </ListItem>
            </Link>
            </List>
        </Box>
      </Modal>
  );
}
export default SP_MenuModal;