import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const usePrompt = (message, when) => {
  const history = useHistory();

  useEffect(() => {
    if (!when) return;

    const unblock = history.block((location) => {
      const confirmLeave = window.confirm(message);
      if (!confirmLeave) {
        // Stay on the current page
        return false;
      }
    });

    return () => {
        unblock();
    };
  }, [message, when, history]);
};

export default usePrompt;
