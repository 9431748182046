import React from 'react';
import { Box, Button, makeStyles, Modal, TextField, Typography } from "@material-ui/core";
import bcrypt from 'bcryptjs';
import CryptoJS from 'crypto-js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles({
  modalTextField:{
    margin: '5px',
    width: '100%'
  }
});


/**
 * パスワード変更用のモーダル
 * @param {*} Login_user_info ログインユーザ情報
 * @param {*} pwModalopen モーダル表示のstate
 * @param {*} setPwModalopen モーダル表示のstateのセッター
 * @returns 
 */
const PasswordEditModal = (props) => {

  // 01_props
  const{Login_user_info, pwModalopen, setPwModalopen} = props;

  const secretKey = 'mamerecoHashKey';

  // 02_useState
  const classes = useStyles()

  // 03_独自関数(eventHandlerなど)
  const handleClose = () => setPwModalopen(false);
  
  const handleSubmitPw = async () =>{

    const inputNowPw = document.getElementById("now_pw").value;
    const inputNewPw = document.getElementById("new_pw").value;
    const inputNewPwRe = document.getElementById("new_pw_re").value;

    const unHashedPw = Login_user_info.pw; 

    // ハッシュパスワードを復号化
    const bytes = CryptoJS.AES.decrypt(Login_user_info.pw, secretKey);
    const hashedPassword = bytes.toString(CryptoJS.enc.Utf8);
    const isMatch = await bcrypt.compare(inputNowPw, hashedPassword);

    if(!isMatch){
      alert("現在のPWと一致しません");
      return;
    }else if(inputNewPw !== inputNewPwRe){
      alert("新しいPWと確認用のPWが一致しません。");
      return;
    }else{
      var result = window.confirm('PWを更新しますか？');
      if( !result ) {
        return;
      }
    }
    
    var url = "/api/update/user_pw"
    var bodyData = {
        user_id:Login_user_info.user_id,
        new_pw:inputNewPw
    }
    const requestOptions ={
        method: 'POST',
        headers:{'Accept': 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify(bodyData)
    };

    fetch(url,requestOptions)

    handleClose();
    alert("pwを更新しました。");

  }

  return (
      <Modal
        open={pwModalopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            パスワード変更
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField label="現在のパスワード" variant="outlined" type="password" autoComplete='new-password' id="now_pw" className={classes.modalTextField} />
            <TextField label="新しいパスワード" variant="outlined" type="password" autoComplete='new-password' id="new_pw" className={classes.modalTextField} />
            <TextField label="新しいパスワード確認用" variant="outlined" type="password" autoComplete='new-password' id="new_pw_re" className={classes.modalTextField} />
          </Typography>
          <Button variant='contained' color='primary' type='submit' className={classes.modalTextField} onClick={() => handleSubmitPw()}>登録</Button>
        </Box>
      </Modal>
  );
}
export default PasswordEditModal;