import React, { useEffect, useState } from 'react';
import { Box, Button, makeStyles, Modal, TextField, Typography } from "@material-ui/core";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles({
  modalTextField:{
    margin: '5px',
    width: '100%'
  }
});


/**
 * CSV出力編集のモーダル
 * @param {*} Login_user_info ログインユーザ情報
 * @param {*} pwModalopen モーダル表示のstate
 * @param {*} setPwModalopen モーダル表示のstateのセッター
 * @returns 
 */
const ExportCSVEditModal = (props) => {

  // 01_props
  const{
    selectedIndex
    , exportCsvMst
    , setExportCsvMst
    , editModalOpen
    , setEditModalOpen
  } = props;

  const [viewName, setViewName] = useState("");
  const [exportName, setExportName] = useState("");

  // 02_useState
  const classes = useStyles()

  useEffect(()=>{
    setViewName(exportCsvMst[selectedIndex]?.view_name);
    setExportName(exportCsvMst[selectedIndex]?.export_name);
  },[selectedIndex])

  // 03_独自関数(eventHandlerなど)
  const handleClose = () => setEditModalOpen(false);
  
  const handleSubmitMst = () =>{

    exportCsvMst[selectedIndex].view_name = viewName;
    exportCsvMst[selectedIndex].export_name = exportName;
    
    setExportCsvMst(exportCsvMst);

    handleClose();

  }

  return (
      <Modal
        open={editModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:'center', marginBottom:'10px'}}>
            {exportCsvMst[selectedIndex]?.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField label="表示名称" variant="outlined" className={classes.modalTextField} value={viewName} onChange={(e)=>setViewName(e.target.value)}/>
            <TextField label="出力名称" variant="outlined" className={classes.modalTextField} value={exportName} onChange={(e)=>setExportName(e.target.value)}/>
          </Typography>
          <Button variant='contained' color='primary' type='submit' className={classes.modalTextField} onClick={() => handleSubmitMst()}>登録</Button>
        </Box>
      </Modal>
  );
}
export default ExportCSVEditModal;