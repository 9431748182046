import { DataGrid, GridToolbarContainer, GridToolbarExport, jaJP } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { Clockin_jarnal_log } from "../classes/clockin_jarnal_log";
import GenericTemplate from "../templates/GenericTemplate";
import * as commonFnc from '../common/commonFnc';
import { Box, Button, MenuItem, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport 
        csvOptions={{
          fileName: 'mamereco',
          utf8WithBom: true
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
    </GridToolbarContainer>
  );
}

const ClockinLogPage = (props) =>{
    const {
      setLogin_user_id, 
      setKintai_bscs, 
      Login_user_info, 
      setDisplay_user_info, 
      Shift_msts, 
      setShift_msts, 
      Users, 
      Department_msts
    } = props;

    
    const SHIFT_START_DAY = 1;

    const [clockin_jarnalsView, setClockin_jarnalsView] = useState([]);
    const [view, setView] = useState([]);

    const now = new Date();
    const [searchDayFrom, setSearchDayFrom] = useState(new Date(now.getFullYear(), now.getMonth(), 1));
    const [searchDayTo, setSearchDayTo] = useState(new Date(now.getFullYear(), now.getMonth()+1, -1));

    const [searchDepartmentGrpCd, setSearchDepartmentGrpCd] = useState(Login_user_info.organization_cd);
    const [searchDepartmentCd, setSearchDepartmentCd] = useState(Login_user_info.department_cd);
    const [searchUserId, setSearchUserId] = useState(Login_user_info.user_id);
    // const [searchUserName, setSearchUserName] = useState(commonFnc.getUserName(Users,Login_user_info.user_id));

    
    if(Shift_msts === null){
      fetch("/api/select/shift_mst")
        .then((res) => res.json())
        .then((data) => setShift_msts(data))
    }

    // 日付を変更したらデータの再取得
    useEffect(async () =>{
      // データ取得
      const queryKintai = new URLSearchParams({target_date_from: commonFnc.formatDate(searchDayFrom), target_date_to: commonFnc.formatDate(searchDayTo)});
      const res = await fetch(`/api/select/clockin_jarnal_atTerm?${queryKintai}`);
      const data = await res.json();
      setClockin_jarnalsView(data);
    },[searchDayFrom]);

    // 部署とユーザを変更したらフィルタ
    useEffect(()=>{
      let tmpClockInJarnals = clockin_jarnalsView.concat();

      // 部署絞り込み
      if(searchDepartmentCd){
        tmpClockInJarnals = tmpClockInJarnals.filter(p=>p.department_cd === searchDepartmentCd);
      }
      
      // IDり込み
      if(searchUserId){
        tmpClockInJarnals = tmpClockInJarnals.filter(p=>p.user_id === searchUserId);
      }

      // // IDり込み
      // if(searchUserName){
      //   tmpClockInJarnals = tmpClockInJarnals.filter(p=>p.user_name === searchUserName);
      // }
      
      let data = [];
      tmpClockInJarnals
        .forEach((clockin_jarnal) => {
          const user = Users.find((p) => p.user_id === clockin_jarnal.user_id);
          const shift = Shift_msts.find((p) => p.shift_type_cd === clockin_jarnal.shift_type_cd);
          const department = Department_msts.find((p) => p.department_cd === clockin_jarnal.department_cd);
          
          let clockInJarnalLog = new Clockin_jarnal_log();
          clockInJarnalLog._id = clockin_jarnal._id;
          clockInJarnalLog.user_id = clockin_jarnal.user_id;
          clockInJarnalLog.user_name = user ? user?.first_name + " " + user?.second_name : "";
          clockInJarnalLog.department_cd = clockin_jarnal.department_cd;
          clockInJarnalLog.department_name = department?.name;
          clockInJarnalLog.shift_type_cd = clockin_jarnal.shift_type_cd;
          clockInJarnalLog.shift_name = shift?.shift_name;
          clockInJarnalLog.clockin_type_cd = clockin_jarnal.clockin_type_cd;
          clockInJarnalLog.clockin_type_name = clockin_jarnal.clockin_type_cd==="10"?"出勤":"退勤";
          clockInJarnalLog.date = clockin_jarnal.date;
          clockInJarnalLog.time = clockin_jarnal.time;
          clockInJarnalLog.createdAt = commonFnc.formatTimestamp(clockin_jarnal.createdAt);
          clockInJarnalLog.updatedAt = commonFnc.formatTimestamp(clockin_jarnal.updatedAt);

          data.push(clockInJarnalLog);
        }, []);
        setView(data)

    },[searchDepartmentCd, searchUserId, clockin_jarnalsView])

    // ログインユーザが変更されたら検索条件も変更
    useEffect(()=>{
      setSearchUserId(Login_user_info.user_id);
    },[Login_user_info])


    /** 日付切り替えボタン */
    const handleButtonClick = (p) =>{

      var d_from = new Date(document.getElementById("target_date_from").value);
      var d_to = new Date(document.getElementById("target_date_to").value);
      
      var addMonth_from = p;
      var addMonth_to = (SHIFT_START_DAY===1 ? p+1 : p)

      setSearchDayFrom(commonFnc.formatDate(new Date(d_from.getFullYear(), d_from.getMonth()+addMonth_from, SHIFT_START_DAY)));
      setSearchDayTo(commonFnc.formatDate(new Date(d_to.getFullYear(), d_to.getMonth()+addMonth_to, SHIFT_START_DAY-1)));
      
    };
  
    /** 全職員表示ボタン */
    const handleAllUserViewButtonClick = () =>{
      
      setSearchDepartmentGrpCd("");
      setSearchDepartmentCd("");
      setSearchUserId("");
    }

    const columns = [
      {field: 'date', headerName: '日付',align: 'center'},
      {field: 'time', headerName: '時刻',align: 'center'},
      {field: 'user_id', headerName: 'ユーザID' ,align: 'center'},
      {field: 'user_name', headerName: '氏名' ,align: 'center'},
      {field: 'department_name', headerName: '部署名称' ,align: 'center'},
      {field: 'shift_name', headerName: 'シフト名称',align: 'center'},
      {field: 'clockin_type_name', headerName: '出退勤名称',align: 'center',color:"red"},
      {field: 'createdAt', headerName: 'レコード作成日時',align: 'center', width: 180,},
      {field: 'updatedAt', headerName: 'レコード更新日時',align: 'center', width: 180,},
      {field: 'department_cd', headerName: '部署コード' ,align: 'center'},
      {field: 'shift_type_cd', headerName: 'シフトコード' ,align: 'center'},
      {field: 'clockin_type_cd', headerName: '出退勤',align: 'center'},
    ]

    return (
        <GenericTemplate title="打刻履歴確認"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        {!Login_user_info? <div class="loader">Loading...</div> :  
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Button onClick={()=>handleButtonClick(-1)}>◁</Button>
                      <TextField 
                        label="打刻日付from" 
                        id="target_date_from" 
                        name="target_date_from" 
                        type="Date" 
                        className="serchBox" 
                        value={commonFnc.formatDate(searchDayFrom)} 
                        inputProps={{style: { textAlign: 'center' }}} 
                        onChange={(e)=>setSearchDayFrom(e.target.value)}
                      />
                      　～　
                      <TextField 
                        label="打刻日付to" 
                        id="target_date_to" 
                        name="target_date_to" 
                        type="Date" 
                        className="serchBox" 
                        value={commonFnc.formatDate(searchDayTo)} 
                        inputProps={{style: { textAlign: 'center' }}} 
                        onChange={(e)=>setSearchDayTo(e.target.value)}
                      />
                      <Button onClick={()=>handleButtonClick(1)}>▷</Button>
                    </TableCell>
                    <div hidden={Login_user_info.authority<11}>
                      <TableCell>
                        <TextField label="施設" id="target_deptgr_cd" name="target_deptgr_cd" select className="serchBox" value={searchDepartmentGrpCd} onChange={(e)=>setSearchDepartmentGrpCd(e.target.value)}>
                          <MenuItem key={-1} value={""}>{""}</MenuItem>
                          {Department_msts.filter(item => item.department_type === 3).map((department_mst, index)=>(
                              <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <TextField label="部署" id="target_dept_cd" name="target_dept_cd" select className="serchBox" value={searchDepartmentCd} onChange={(e)=>setSearchDepartmentCd(e.target.value)}>
                          <MenuItem key={-1} value={""}>{""}</MenuItem>
                          {Department_msts.filter(item => item.department_type === 5 && item.highly_department_cd === searchDepartmentGrpCd).map((department_mst, index)=>(
                              <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <TextField label="職員ID" id="target_user_id" name="target_user_id" className="serchBox" value={searchUserId} onChange={(e)=>setSearchUserId(e.target.value)}/>
                        {/* <TextField label="氏名" id="target_user_name" name="target_user_name" className="serchBox" value={searchUserName} onChange={(e)=>setSearchUserName(e.target.value)}/> */}
                      </TableCell>
                      <TableCell>
                        <Button className="acction_button" variant="outlined" onClick={()=>handleAllUserViewButtonClick()}>全職員</Button>
                      </TableCell>
                    </div>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <Box
              sx={{
                height: "70vh",
                width: '100%',
                '& .blue': {
                  backgroundColor: '#b9d5ff91',
                  color: '#1a3e72',
                },
                '& .red': {
                  backgroundColor: '#ff943975',
                  color: '#1a3e72',
                },
                '& .rowRed': {
                  backgroundColor: 'Gold',
                  color: 'Red',
                },
              }}
            >
              <DataGrid
                  rows={view}
                  columns={columns}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  autoPageSize={true}
                  autoWidth={true}
                  density={"compact"}
                  checkboxSelection
                  disableSelectionOnClick
                  getRowId={(view) => view._id}
                  components={{
                    Toolbar: CustomToolbar,// ツールバーを指定する
                  }}
                  localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                  getRowClassName={(params) => {
                    // console.log(params)
                    if(params.row.createdAt!==params.row.updatedAt) return "rowRed";
                    return '';
                  }}
                  getCellClassName={(params) => {
                    if(params.field==="clockin_type_name") return params.value === "出勤" ? 'red' : 'blue';
                    return '';
                  }}
              />
            </Box>
          </div>
        }
        </GenericTemplate>
    );
}

export default ClockinLogPage;