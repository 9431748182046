import { useContext } from "react";
import {GlobalContext} from '../globalState'
import ClockinFormTouch from "../conponents/ClockinFormTouch";
import ClockinFormNfc from "../conponents/ClockinFormNfc";
import '../css/Loading.css';


const ClockInPage = (props) =>{
    // const classes = useStyles();
    const {Clockin_info, setClockin_info, Users, Shift_msts, Department_msts, Login_user_info} = props;

    // CLOCKIN_MODE  TOUCH:タッチで打刻 NFC:NFCで打刻
    const Env_msts = useContext(GlobalContext).Env_msts;
    const CLOCKIN_MODE = Env_msts.find((p)=>p.env_key === "CLOCKIN_MODE")?.env_value;


    return (
      <div>
      {!Env_msts?<div class="loader">Loading...</div>:
      <div>
      {CLOCKIN_MODE==="TOUCH"?
        <ClockinFormTouch
          Clockin_info={Clockin_info}
          setClockin_info={setClockin_info}
          Users={Users}
          Shift_msts={Shift_msts}
          Department_msts={Department_msts}
          Login_user_info={Login_user_info}
        ></ClockinFormTouch>
        :
        <ClockinFormNfc
          Clockin_info={Clockin_info}
          setClockin_info={setClockin_info}
          Users={Users}
          Shift_msts={Shift_msts}
          Department_msts={Department_msts}
          Login_user_info={Login_user_info}
        ></ClockinFormNfc>
      }
      </div>
      }
      </div>
    );
}

export default ClockInPage;