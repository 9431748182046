import { useState } from "react";
import RequestShiftMstForm from "../conponents/RequestShiftMstForm";
import RequestShiftMstList from "../conponents/RequestShiftMstList";
import GenericTemplate from "../templates/GenericTemplate";
import '../css/Loading.css';

const RequestShiftMstPage = (props) =>{
    const {setLogin_user_id, setKintai_bscs, Login_user_info, setDisplay_user_info, Shift_msts, setShift_msts} = props;
    const [isViewLogDelShift, setIsLogDelShift] = useState(false);
    const [Shift_mst, setShift_mst] = useState({
        shift_type_cd: "",
        shift_name: "",
        shift_time_from: "",
        shift_time_to: "",
        rest_time: "",
        use_day_from: "",
        use_day_to: "",
        shift_group_cd:"",
        public_holiday_time:"",
        paid_holiday_time:"",
        note: "",
        seq: "",
        backgroundcolor: "",
        textcolor: "",
        shift_switching_time:"",
        shift_time_to_date_flg:"1",
        log_del_flg:false,
    })

    return (
        <GenericTemplate title="シフトマスタ管理"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        {!Shift_mst || !Login_user_info ? <div class="loader">Loading...</div> : 
            <div className="shiftMstPage_wrapper">
                <div className="shiftMstPage_content">
                    <RequestShiftMstList
                        Shift_msts={Shift_msts} 
                        setShift_msts={setShift_msts}
                        setShift_mst={setShift_mst}
                        isViewLogDelShift={isViewLogDelShift}
                        setIsLogDelShift={setIsLogDelShift}
                        Login_user_info={Login_user_info}
                    />
                </div>
                <div className="shiftMstPage_content">
                    <RequestShiftMstForm
                        Shift_mst={Shift_mst}
                        setShift_mst={setShift_mst}
                    />
                </div>
            </div>
        }
        </GenericTemplate>
    );
}

export default RequestShiftMstPage;