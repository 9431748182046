/**
 * CSV出力情報
 * @type {Export_csv_mst}
 */
export class Export_csv_mst {

    constructor(){
        
        this.column_name = ""; // まれこの物理名称
        this.name = ""; // まれこの物理名称
        this.view_name = ""; // ユーザの表示名称
        this.export_name = ""; // エクスポート時の名称
        this.note = "";
        this.shift_type_cd = "";
        this.shift_count_flg = "";
        this.seq = "";
    }

}