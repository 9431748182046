import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Button, FormControlLabel, Switch } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


// name: DepMstList
// summary: 部署マスタ編集画面_部署リストコンポーネント
// prop1: Department_msts 部署情報
// prop2: setDepartment_mst Department_mstのセッター
// prop3: setUsers Usersのセッター
const DepMstList = (props) => {
  
  // 01_props
  const {Department_msts, Department_mst, setDepartment_mst} = props;

  // 02_useState
  const classes = useStyles();
  
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isViewEndDep, setIsViewEndDep] = useState(false);
  
  // 03_独自関数(eventHandlerなど)
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };
  
  const handleSelect = (e, nodeIds) => {
    setSelected(nodeIds);
    
    const query = new URLSearchParams({department_cd: nodeIds});
    fetch(`/api/select/department_mst?${query}`)
      .then((res) => res.json())
      .then((data) => setDepartment_mst(data)
      );

  };
  return (
    <div className={classes.root}>
    <div>
      <FormControlLabel
          control={<Switch checked={isViewEndDep} onChange={()=>setIsViewEndDep(!isViewEndDep)} />}
          label="期限切れの部署も表示"
      />
    </div>
    <TreeView
      className="depmstTreeView"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}>
      {Department_msts.filter(p=>new Date(p?.use_end_day ? p.use_end_day : "2100-12-31")>(isViewEndDep ? new Date("1900-12-31") : new Date())).sort((a, b) => a.seq < b.seq ? -1 : 1).map((dep_mst, index)=>(
        dep_mst.department_type === 3 &&
          <TreeItem 
            nodeId={dep_mst.department_cd} 
            label={dep_mst.name + "　(" + dep_mst.department_cd + ")"}
          >
              {Department_msts.filter(p=>new Date(p?.use_end_day ? p.use_end_day : "2100-12-31")>(isViewEndDep ? new Date("1900-12-31") : new Date())).sort((a, b) => a.seq < b.seq ? -1 : 1).map((dep_mst2, index)=>(
                dep_mst2.highly_department_cd === dep_mst.department_cd &&
                <TreeItem 
                  nodeId={dep_mst2.department_cd} 
                  label={dep_mst2.name + "　(" + dep_mst2.department_cd + ")"} 
                  className="depmstTreeItem"
                  style={{borderLeft:dep_mst2.department_cd===Department_mst.department_cd?'#8dbb5a 5px solid':''}}
                />
              ))}
          </TreeItem>
      ))}
      </TreeView>
    </div>
  );
}
export default DepMstList;