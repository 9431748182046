import React from 'react';
import { Box, Button, makeStyles, Modal, TextField, Typography } from "@material-ui/core";
import * as BL_approval from '../BL/BL_approval';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles({
  modalTextField:{
    margin: '5px',
    width: '100%'
  }
});


// name: ApprovalsTableKntaiReturnModal
// summary: 勤怠差戻し用のモーダルフォーム
// prop1: Kintai_bsc モーダルに表示する勤怠情報
const ApprovalsTableKntaiReturnModal = (props) => {

  // 01_props
  const{
    Kintai_bsc
    , setKintai_bsc
    , approvalReturnModalOpen
    , setApprovalReturnModalOpen
    , Department_msts
    , setApproval_kintai_bscs
    , login_user_id
    , urlPrefix
    , getApproval_kintai_bsc
  } = props;

  // 02_useState
  const classes = useStyles()

  // 03_独自関数(eventHandlerなど)
  const handleClose = () => setApprovalReturnModalOpen(false);
  
  const handleInputChage = async (e) =>{
    const target = e.target;
    const value = target.value;
    const name = target.name;
    await setKintai_bsc({ ...Kintai_bsc, [name]: value });
  } 

  const handleConfirmButtonClick = async () =>{
    await BL_approval.requestReturn(urlPrefix, Kintai_bsc, Department_msts, setApproval_kintai_bscs, login_user_id);
    getApproval_kintai_bsc();
    handleClose();
  } 

  return (
      <Modal
        open={approvalReturnModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="/api/update/department_mst" method='POST'>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              勤怠差戻し
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {!Kintai_bsc ? "" :
              <TextField
                label="差戻し理由"
                variant="outlined"
                name="approval_note"
                multiline
                minRows="3"
                className={classes.modalTextField}
                onChange={e => handleInputChage(e)}
                value={Kintai_bsc.approval_note}
                />
                }
            </Typography>
              <Button variant='contained' color='primary' className={classes.modalTextField} onClick={()=>handleConfirmButtonClick()}>登録</Button>
          </Box>
        </form>
      </Modal>
  );
}
export default ApprovalsTableKntaiReturnModal;