export class Kintai_Summary_Info {

    // メンバー
    constructor(kintaiSummaryInfo, user, dept, deptgr, Users, Kintai_bscs_atMonth) {

        
        let appUser1 = user?Users.find( item => item.user_id === user.first_approval_userid):null;
        let appUser2 = user?Users.find( item => item.user_id === user.second_approval_userid):null;
        let depAppUser1 = dept?Users.find( item => item.user_id === dept.first_approval_userid):null;
        let depAppUser2 = dept?Users.find( item => item.user_id === dept.second_approval_userid):null;


        this.user_id = kintaiSummaryInfo._id;
        this.user_name = user.first_name + " " + user.second_name;
        this.organization_cd = deptgr?.department_cd;
        this.organization_name = deptgr?.name;
        this.department_cd = dept?.department_cd;;
        this.department_name = dept?.name;
        this.first_approval_userid = user.first_approval_userid;
        this.first_approval_name = appUser1 ? appUser1?.first_name + " " + appUser1?.second_name : "";
        this.second_approval_userid = user.second_approval_userid;;
        this.second_approval_name = appUser2 ? appUser2?.first_name + " " + appUser2?.second_name : "";
        this.department_first_approval_userid = dept?.first_approval_userid;
        this.department_first_approval_name = depAppUser1 ? depAppUser1?.first_name + " " + depAppUser1?.second_name : "";
        this.department_second_approval_userid = dept?.second_approval_userid;;
        this.department_second_approval_name = depAppUser2 ? depAppUser2?.first_name + " " + depAppUser2?.second_name : "";
        this.sum_work_time = kintaiSummaryInfo.sum_work_time;
        this.sum_midnight_work_time = kintaiSummaryInfo.sum_midnight_work_time;
        this.sum_rest_time = kintaiSummaryInfo.sum_rest_time;
        this.sum_over_time = kintaiSummaryInfo.sum_over_time;
        this.sum_midnight_over_time = kintaiSummaryInfo.sum_midnight_over_time;
        this.sum_behind_time = kintaiSummaryInfo.sum_behind_time;
        this.sum_early_time = kintaiSummaryInfo.sum_early_time;
        this.sum_out_time = kintaiSummaryInfo.sum_out_time;
        this.sum_training_time = kintaiSummaryInfo.sum_training_time;
        this.sum_public_holiday_time = kintaiSummaryInfo.sum_public_holiday_time;
        this.sum_paid_holiday_time = kintaiSummaryInfo.sum_paid_holiday_time;
        this.count_before = Kintai_bscs_atMonth.filter(item => item.approval_status_cd === "10" || item.approval_status_cd === "80").length;
        this.count_wait1 = Kintai_bscs_atMonth.filter(item => item.approval_status_cd === "20").length;
        this.count_wait2 = Kintai_bscs_atMonth.filter(item => item.approval_status_cd === "30").length;
        this.count_commit = Kintai_bscs_atMonth.filter(item => item.approval_status_cd === "90").length;
    }
    
    // getter/setter
    get getUser_Id() {
        return this.User_Id;
    }

    set setUser_Id(user_id) {
        this.user_id = user_id;
    }

    get getUser_Name() {
        return this.User_Name;
    }

    set setUser_Name(user_name) {
        this.user_name = user_name;
    }

    get getOrganization_Cd() {
        return this.Organization_Cd;
    }

    set setOrganization_Cd(organization_cd) {
        this.organization_cd = organization_cd;
    }

    get getOrganization_Name() {
        return this.Organization_Name;
    }

    set setOrganization_Name(organization_name) {
        this.organization_name = organization_name;
    }

    get getDepartment_Cd() {
        return this.Department_Cd;
    }

    set setDepartment_Cd(department_cd) {
        this.department_cd = department_cd;
    }

    get getDepartment_Name() {
        return this.Department_Name;
    }

    set setDepartment_Name(department_name) {
        this.department_name = department_name;
    }

    get getFirst_Approval_Userid() {
        return this.First_Approval_Userid;
    }

    set setFirst_Approval_Userid(first_approval_userid) {
        this.first_approval_userid = first_approval_userid;
    }

    get getFirst_Approval_Name() {
        return this.First_Approval_Name;
    }

    set setFirst_Approval_Name(first_approval_name) {
        this.first_approval_name = first_approval_name;
    }

    get getSecond_Approval_Userid() {
        return this.Second_Approval_Userid;
    }

    set setSecond_Approval_Userid(second_approval_userid) {
        this.second_approval_userid = second_approval_userid;
    }

    get getSecond_Approval_Name() {
        return this.Second_Approval_Name;
    }

    set setSecond_Approval_Name(second_approval_name) {
        this.second_approval_name = second_approval_name;
    }

    get getSum_Work_Time() {
        return this.Sum_Work_Time;
    }

    set setSum_Work_Time(sum_work_time) {
        this.sum_work_time = sum_work_time;
    }

    get getSum_Rest_Time() {
        return this.Sum_Rest_Time;
    }

    set setSum_Rest_Time(sum_rest_time) {
        this.sum_rest_time = sum_rest_time;
    }

    get getSum_Over_Time() {
        return this.Sum_Over_Time;
    }

    set setSum_Over_Time(sum_over_time) {
        this.sum_over_time = sum_over_time;
    }

    get getSum_Midnight_Over_Time() {
        return this.Sum_Midnight_Over_Time;
    }

    set setSum_Midnight_Over_Time(sum_midnight_over_time) {
        this.sum_midnight_over_time = sum_midnight_over_time;
    }

    get getSum_Behind_Time() {
        return this.Sum_Behind_Time;
    }

    set setSum_Behind_Time(sum_behind_time) {
        this.sum_behind_time = sum_behind_time;
    }

    get getSum_Early_Time() {
        return this.sum_early_time;
    }

    set setSum_Early_Time(sum_early_time) {
        this.sum_early_time = sum_early_time;
    }

    get getSum_Out_Time() {
        return this.Sum_Out_Time;
    }

    set setSum_Out_Time(sum_out_time) {
        this.sum_out_time = sum_out_time;
    }

    get getSum_Training_Time() {
        return this.Sum_Training_Time;
    }

    set setSum_Training_Time(sum_training_time) {
        this.sum_training_time = sum_training_time;
    }

    get getSum_public_holiday_time() {
        return this.sum_public_holiday_time;
    }

    set setSum_public_holiday_time(sum_public_holiday_time) {
        this.sum_public_holiday_time = sum_public_holiday_time;
    }

    get getSum_paid_holiday_time() {
        return this.sum_paid_holiday_time;
    }

    set setSum_paid_holiday_time(sum_paid_holiday_time) {
        this.count_paid_holiday = sum_paid_holiday_time;
    }


}