 /**
   * オブジェクトの必須チェック
   * @param {*} errors 
   * @param {*} target 
   * @param {*} name 
   */
export function isValidRequired (errors, target, name) {
    if (target[name] === undefined || target[name] === null || target[name] === '') {
        errors[name] = '必須項目です';
    }
}



/**
 * 日付形式のチェック
 * @param {*} dateString 
 * @returns 
 */
export function isValidDate (errors, target, name) {
    // 日付形式をチェックする正規表現 (YYYY-MM-DD)
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(target[name])) {
        errors[name] = '日付の形式ではありません';
    }
    const date = new Date(target[name]);
    const timestamp = date.getTime();
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        errors[name] = '日付の形式ではありません';
    }
};


/**
 * 数値型のチェック
 * @param {*} errors
 * @param {*} target
 * @param {*} name
 * @param {*} min
 * @param {*} max
 */
export function isValidNumber(errors, target, name, min = -Infinity, max = Infinity) {
    const value = target[name];
    if (value === undefined || value === null || value === '') {
        errors[name] = '数値は必須です';
    } else if (isNaN(value)) {
        errors[name] = '数値を入力してください';
    } else {
        const number = parseFloat(value);
        if (number < min || number > max) {
            errors[name] = `数値は${min}から${max}の間でなければなりません`;
        }
    }
}


/**
 * 文字列の長さの上限チェック
 * @param {*} errors
 * @param {*} target
 * @param {*} name
 * @param {*} maxLength
 */
export function isValidStringLength(errors, target, name, maxLength) {
    const value = target[name];
    if (value && value.length > maxLength) {
        errors[name] = `文字列は${maxLength}文字以下でなければなりません`;
    }
}