import React, { useContext, useState } from "react";
import clsx from "clsx";
import { createTheme } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CloudUpload from '@material-ui/icons/CloudUpload';
import AccessAlarms from '@material-ui/icons/AccessAlarms';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import PeopleIcon from '@material-ui/icons/People';
import PasswordEditModal from "../conponents/PasswordEditModal";
import {GlobalContext} from '../globalState'
import * as commonFnc from '../common/commonFnc';
import * as BL_information from '../BL/BL_information';
import InfoIcon from '@material-ui/icons/Info';

const drawerWidth = 240;

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    // primary: { main: colors.blue[800] }, // テーマの色
    primary: { main: colors.lightGreen[400] }, // テーマの色
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      height: "100vh",
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  })
);

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/ClockinPage">
        tk3stars.inc
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

// export interface GenericTemplateProps {
//   children: React.ReactNode;
//   title: string;
// }


const GenericTemplate = ({
  children,
  title,
  setLogin_user_id,
  setKintai_bscs,
  Login_user_info,
  setDisplay_user_info,
}) => {

  const classes = useStyles();

  const Env_msts = useContext(GlobalContext).Env_msts;
  const company_name = Env_msts.find(p=>p.env_key === "COMPANY_NAME")?.env_value;
  const scrollInfomationList = useContext(GlobalContext).scrollInfomationList;
  let  view_msg = "";
  scrollInfomationList.filter(p=>BL_information.isView(p)).forEach((scrollInfomationList)=>{
    view_msg += scrollInfomationList.title + " : " + scrollInfomationList.contents.slice(0,20) + "...　　　　　"
  })


  const [pwModalopen, setPwModalopen] = useState(false);
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickKintaiLink = () =>{
    
    const query = new URLSearchParams({login_user_id: Login_user_info.user_id});
    fetch(`/api/select/kintai_bsc?${query}`)
      .then((res) => res.json())
      .then((data) => setKintai_bscs(data));
    
    setDisplay_user_info(Login_user_info);
  }
  
  const handlePwModalOpne = () => {
    setPwModalopen(true);
  };

  const logout = () =>{

    commonFnc.insertUserLog("INFO",Login_user_info.user_id, __filename, "ヘッダー", "ログアウト", "画面遷移", "ログアウトボタンクリック", null, null);
    setLogin_user_id("");
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        {!Login_user_info?"":
        <div>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
            <span class="header_company">{company_name}</span>
            <span class="header_username">
              {Login_user_info.first_name + " " + Login_user_info.second_name + "さん"}
            </span>
            
             
            {/* まめれこ病院 　{Login_user_info.first_name + " " + Login_user_info.second_name + "さん"} */}
            </Typography>
            <a class="news-banner" href="/InformationPage">
              <div class="news-banner__content">
                <p>{view_msg.length>0?view_msg:"新着メッセージはありません。"}</p>
              </div>
            </a>
            
            <Typography
              className={classes.link} 
              onClick={()=>handlePwModalOpne()}
              style = {{"margin-right": "15px","text-decoration":"underline"}}
            >
              パスワード変更
            </Typography>
            <Link to="/">
              <Typography
                className={classes.link} 
                onClick={()=>logout()}
              >
                ログアウト
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <Link to="/Kintai" className={classes.link} onClick={()=>handleClickKintaiLink()}>
              <ListItem button>
                <ListItemIcon>
                  <InsertInvitationIcon />
                </ListItemIcon>
                <ListItemText primary="勤務カレンダー" />
              </ListItem>
            </Link>
            {Login_user_info.authority===10?"":
            <Link to="/Approvals" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText primary="承認待ち" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority===10?"":
            <Link to="/ApprovalsUserList" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary="勤務集計" />
              </ListItem>
            </Link>
            }
            <Link to="/ClockinLogPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <TouchAppIcon />
                </ListItemIcon>
                <ListItemText primary="打刻履歴" />
              </ListItem>
            </Link>
            {Login_user_info.authority===10?"":
            <Link to="/Mst_depertmentMstPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <NaturePeopleIcon />
                </ListItemIcon>
                <ListItemText primary="部署マスタ管理" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority===10?"":
            <Link to="/Mst_UserMstPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="ユーザマスタ管理" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority===10?"":
            <Link to="/RequestShiftMstPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <ScheduleIcon />
                </ListItemIcon>
                <ListItemText primary="シフトマスタ管理" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority===10?"":
            <Link to="/Mst_publicHolidayMstPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <AccessAlarms />
                </ListItemIcon>
                <ListItemText primary="公休マスタ管理" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority===10?"":
            <Link to="/ExportCSVMstPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="CSV出力項目マスタ" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority===10?"":
            <Link to="/ImportShiftPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <CloudUpload />
                </ListItemIcon>
                <ListItemText primary="勤務シフト取込" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority===10?"":
            <Link to="/ExportCsvPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <CloudDownload />
                </ListItemIcon>
                <ListItemText primary="CSVダウンロード" />
              </ListItem>
            </Link>
            }
            <Link to="/InformationPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="お知らせ" />
              </ListItem>
            </Link>
            {Login_user_info.authority!==90?"":
            <Link to="/EnvMstPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="環境変数マスタ" />
              </ListItem>
            </Link>
            }
            {Login_user_info.authority!==90?"":
            <Link to="/jarnalView" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="各種履歴" />
              </ListItem>
            </Link>
            }
          </List>
        </Drawer>
        </div>
        }
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {/* <Container maxWidth="lg" className={classes.container}> */}
          <Container maxWidth="xl" className={classes.container}>
            <Typography
              component="h1"
              variant="h5"
              color="inherit"
              noWrap
              className={classes.pageTitle}
            >
              <h2 class="template_title">{title}</h2>
            </Typography>
            {children}
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
      <PasswordEditModal
        Login_user_info={Login_user_info}
        pwModalopen={pwModalopen}
        setPwModalopen={setPwModalopen}
      >
      </PasswordEditModal>
    </ThemeProvider>
  );
};

export default GenericTemplate;