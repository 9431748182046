import React, { useContext, useState } from "react";
import clsx from "clsx";
import { createTheme } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import PasswordEditModal from "../conponents/PasswordEditModal";
import {GlobalContext} from '../globalState'
import * as commonFnc from '../common/commonFnc';
import * as BL_information from '../BL/BL_information';
import SP_MenuModal from "../conponents/SP_MenuModal";

const drawerWidth = 240;

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    // primary: { main: colors.blue[800] }, // テーマの色
    primary: { main: colors.lightGreen[400] }, // テーマの色
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      fontSize:'1.2rem',
      textAlign:'center', 
      color:'snow',
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  })
);


const SPTemplate = ({
  children,
  title,
  setLogin_user_id,
  setKintai_bscs,
  Login_user_info,
  setDisplay_user_info,
}) => {

  const classes = useStyles();

  const Env_msts = useContext(GlobalContext).Env_msts;
  const company_name = Env_msts.find(p=>p.env_key === "COMPANY_NAME")?.env_value;

  const [pwModalopen, setPwModalopen] = useState(false);
  const [menuModalOpen, setMenuModalOpen] = useState(false)
  const [open, setOpen] = React.useState(false);

  const handleMenuModalOpen = () => {
    setMenuModalOpen(true);
  };

  const logout = () =>{

    commonFnc.insertUserLog("INFO",Login_user_info.user_id, __filename, "ヘッダー", "ログアウト", "画面遷移", "ログアウトボタンクリック", null, null);
    setLogin_user_id("");
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <div>
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleMenuModalOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
              {company_name}
              </Typography>
              <Link to="/">
                <Typography
                  className={classes.link} 
                  onClick={()=>logout()}
                >
                  {Login_user_info?Login_user_info.first_name + " " + Login_user_info.second_name + "さん":"ログイン"}
                </Typography>
              </Link>
            </Toolbar>
          </AppBar>
        </div>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            {children}
          </Container>
        </main>
      </div>
      <SP_MenuModal
        Login_user_info={Login_user_info}
        menuModalOpen={menuModalOpen}
        setMenuModalOpen={setMenuModalOpen}
        setPwModalopen={setPwModalopen}
      />
      <PasswordEditModal
        Login_user_info={Login_user_info}
        pwModalopen={pwModalopen}
        setPwModalopen={setPwModalopen}
      />
    </ThemeProvider>
  );
};

export default SPTemplate;