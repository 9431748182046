import * as commonFnc from '../common/commonFnc';

/**
 * 公開中のお知らせか判定する。
 * @param {*} Information 
 * @returns 
 */
export function isView(Information){
    const inter = (commonFnc.formatDate(new Date()) >= Information.from_day) && (commonFnc.formatDate(new Date()) <= Information.to_day);
    const release = Information.release_flg;
    const log_del_flg = Information.log_del_flg;

    return inter && release && !log_del_flg

    
}
