
import Button from "@material-ui/core/Button";
import { MenuItem, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useState} from "react";
import * as commonFnc from '../common/commonFnc';


const ApprovalsHeader = (props) =>{
    const {
        Shift_msts
        , Department_msts
        , serchCondition
        , setSerchCondition
        , getApproval_kintai_bsc
        , loading
        , setLoading
        , Login_user_info
    } = props;

    const [deptgr_cd, setDeptgr_cd] = useState(serchCondition.target_deptgr_cd);
    const SHIFT_START_DAY = 1;
    
    const handleInputChage = (e) =>{
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setSerchCondition({ ...serchCondition, [name]: value });
        
    } 

    const handleOnClickSearchButton = () => {
        getApproval_kintai_bsc();
        // setSerchCondition({
        //     target_date_from : document.getElementById("target_date_from").value,
        //     target_date_to : document.getElementById("target_date_to").value,
        //     target_deptgr_cd : document.getElementsByName("target_deptgr_cd")[0].value,
        //     target_dept_cd : document.getElementsByName("target_dept_cd")[0].value,
        //     target_user_id : document.getElementById("target_user_id").value,
        //     target_user_name : document.getElementById("target_user_name").value,
        //     target_shift_type_cd : document.getElementById("target_shift_type_cd").value,
        // })
    };

    const handleOnClickClearButton = () => {
        setSerchCondition({
            ...serchCondition,
            target_deptgr_cd : "",
            target_dept_cd : "",
            target_user_id : "",
            target_user_name : "",
            target_shift_type_cd : "",
            exist_over_time : 0, //　残業ありなし両方
        })
    };

    
    const handleButtonClick = (p) =>{

        var d_from = new Date(document.getElementById("target_date_from").value);
        var d_to = new Date(document.getElementById("target_date_to").value);
        
        var addMonth_from = p;
        var addMonth_to = (SHIFT_START_DAY===1 ? p+1 : p)

        setSerchCondition({
            target_date_from : commonFnc.formatDate(new Date(d_from.getFullYear(), d_from.getMonth()+addMonth_from, SHIFT_START_DAY)),
            target_date_to : commonFnc.formatDate(new Date(d_to.getFullYear(), d_to.getMonth()+addMonth_to, SHIFT_START_DAY-1)),
            target_deptgr_cd : "",
            target_dept_cd : "",
            target_user_id : "",
            target_user_name : "",
            target_shift_type_cd : "",
            exist_over_time : 0, //　残業ありなし両方
        })
        
    };

    
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Button onClick={()=>handleButtonClick(-1)}>◁</Button>
                            <TextField label="日付from" id="target_date_from"  name="target_date_from" type="Date" className="serchBox" value={serchCondition.target_date_from} inputProps={{style: { textAlign: 'center' }}} onChange={(e)=>handleInputChage(e)}/>
                            　～　
                            <TextField label="日付to" id="target_date_to" name="target_date_to"  type="Date" className="serchBox" value={serchCondition.target_date_to} inputProps={{style: { textAlign: 'center' }}} onChange={(e)=>handleInputChage(e)}/>
                            <Button onClick={()=>handleButtonClick(1)}>▷</Button>
                        </TableCell>
                        <TableCell>
                            <TextField label="施設" id="target_deptgr_cd" name="target_deptgr_cd" select className="serchBox" value={serchCondition.target_deptgr_cd} onChange={(e)=>{handleInputChage(e); setDeptgr_cd(e.target.value)}}>
                                {Department_msts.filter(item => item.department_type === 3).map((department_mst, index)=>(
                                    <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                                ))}
                            </TextField>
                        </TableCell>
                        <TableCell>
                            <TextField label="部署" id="target_dept_cd" name="target_dept_cd" select className="serchBox" value={serchCondition.target_dept_cd} onChange={(e)=>handleInputChage(e)}>
                            <MenuItem key={-1} value={""}></MenuItem>
                                {Department_msts.filter(item => item.department_type === 5 && item.highly_department_cd === deptgr_cd).map((department_mst, index)=>(
                                    <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                                ))}
                            </TextField>
                        </TableCell>
                        <TableCell>
                            <TextField label="シフト" id="target_shift_type_cd" name="target_shift_type_cd" select className="serchBox" value={serchCondition.target_shift_type_cd} onChange={(e)=>handleInputChage(e)}>
                                {Shift_msts.map((shift_mst, index)=>(
                                    <MenuItem key={index} value={shift_mst.shift_type_cd}>{shift_mst.shift_name}</MenuItem>
                                ))}
                            </TextField>
                        </TableCell>
                        <TableCell>
                            <TextField label="職員ID" id="target_user_id" name="target_user_id" className="serchBox" value={serchCondition.target_user_id} onChange={(e)=>handleInputChage(e)}/>
                        </TableCell>
                        <TableCell>
                            <TextField label="氏名" id="target_user_name" name="target_user_name" className="serchBox" value={serchCondition.target_user_name} onChange={(e)=>handleInputChage(e)}/>
                        </TableCell>
                            <div>
                                <Button className="headerActionButton" variant={serchCondition.exist_over_time===1?"contained":"outlined"} onClick={() => setSerchCondition({ ...serchCondition, exist_over_time: 1 })}>残業なし</Button>
                                <Button className="headerActionButton" variant={serchCondition.exist_over_time===2?"contained":"outlined"} onClick={() => setSerchCondition({ ...serchCondition, exist_over_time: 2 })}>残業あり</Button>
                                <Button className="headerActionButton" variant="outlined" onClick={() => handleOnClickClearButton()}>クリア</Button>
                            </div>
                            <div>
                            <Button className="headerSearchButton" variant="outlined" onClick={() => handleOnClickSearchButton()}>検索</Button>
                            </div>
                    </TableRow>
                </TableHead>
            </Table>
        </TableContainer>
    )
}
export default ApprovalsHeader;