export class Clockin_jarnal_log {

    constructor(){
        this._id = ""; // _id
        this.user_id = ""; // 従業員コード
        this.user_name = ""; // 従業員氏名
        this.department_cd = ""; // 部門コード
        this.department_name = ""; // 部門名称
        this.shift_type_cd = ""; // シフトタイプコード
        this.shift_name = ""; // シフト名称
        this.clockin_type_cd = ""; // 打刻タイプコード
        this.clockin_type_name = ""; // 打刻タイプ名称
        this.date=""; // 打刻日
        this.time=""; // 打刻時刻
        this.createdAt=""; // レコード作成時刻
        this.updateAt=""; // レコード更新時刻
    }


}