import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import React from "react";
import { useEffect,useState } from "react";


// summary: IDと名称を登録するダイアルログを表示する。
// param : String msg 表示文言
// param : noolean isOpen ダイアログ表示状態
// param : function doYes Yes押下時アクション
// param : function doNo No押下時アクション
// param : function setObj TextFieldに表示するオブジェクトのセッター
// param : function Obj TextFieldに表示するオブジェクト
// param : function idColumn IDのカラム名
// param : function nameColumn 名称のカラム名
// param : function seqColumn 並びのカラム名
// param : function processType 新規：create　更新：update
// return : function 


function CommonIdNameSeqDialog ({msg, isOpen, doYes, doNo, setObj, Obj, idColumn, nameColumn, seqColumn, isIdColumnDisabled}){

    const [open, setOpen] = useState(false)


    const handleInputChage = (e) =>{

        var name = e.target.name;
        var value = e.target.value;

        setObj({
            ...Obj,
            [name]: value
        })
        
    }

    useEffect(() => {
        setOpen(isOpen)
      }, [isOpen])
    
    return (
        
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={()=>doNo()}
                aria-labelledby="common-dialog-title"
                aria-describedby="common-dialog-description"
                >
                <DialogContent>
                    <div>
                        {msg}
                    </div> 
                    <div class="dialogTextBox">
                        <TextField  InputLabelProps={{ shrink: true }} label="公休タイプCD" variant="outlined" name={idColumn}
                            type="Number"
                            onChange={e => handleInputChage(e)}
                            value={Obj[idColumn] ? Obj[idColumn] : ""}
                            style = {{width: "400px"}} 
                            disabled={isIdColumnDisabled}
                        />
                    </div>
                    <div class="dialogTextBox">
                        <TextField  InputLabelProps={{ shrink: true }} label="名称" variant="outlined" name={nameColumn}
                            type="text"
                            onChange={e => handleInputChage(e)}
                            value={Obj[nameColumn] ? Obj[nameColumn] : ""}
                            style = {{width: "400px"}} 
                        />
                    </div>
                    <div class="dialogTextBox">
                        <TextField  InputLabelProps={{ shrink: true }} label="並び" variant="outlined" name={seqColumn}
                            type="Number"
                            onChange={e => handleInputChage(e)}
                            value={Obj[seqColumn] ? Obj[seqColumn] : ""}
                            style = {{width: "400px"}} 
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => doNo()} color="primary">
                    キャンセル
                    </Button>
                    <Button onClick={() => doYes()} color="primary">
                    OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
export default CommonIdNameSeqDialog