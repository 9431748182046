import RequestKintaiForm from "../conponents/RequestKintaiForm";
import GenericTemplate from "../templates/GenericTemplate";
import * as commonFnc from '../common/commonFnc';
import SPTemplate from "../templates/SPTemplate";

const RequestKintai = (props) =>{
    const {
        login_user_id, 
        setLogin_user_id, 
        Shift_msts, 
        setKintai_bscs, 
        Kintai_bsc, 
        setKintai_bsc, 
        Request_overtimes,
        setRequest_overtimes, 
        Kintai_outtime, 
        setKintai_outtime, 
        Request_paid_holiday,
        setRequest_paid_holiday, 
        Login_user_info, 
        setLogin_user_info, 
        display_user_info, 
        setDisplay_user_info, 
        Department_msts,
        triggerReloadData,
        setTriggerReloadData,
    } = props;
    return (
        <>
        {!commonFnc.isMobile()?
        <GenericTemplate title="勤怠登録画面"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
            <RequestKintaiForm 
            login_user_id={login_user_id} 
            Shift_msts={Shift_msts} 
            setKintai_bscs={setKintai_bscs}
            Kintai_bsc={Kintai_bsc} 
            setKintai_bsc={setKintai_bsc}
            Request_overtimes={Request_overtimes}
            setRequest_overtimes={setRequest_overtimes}
            Kintai_outtime={Kintai_outtime}
            setKintai_outtime={setKintai_outtime}
            Request_paid_holiday={Request_paid_holiday}
            setRequest_paid_holiday={setRequest_paid_holiday}
            Login_user_info={Login_user_info}
            setLogin_user_info={setLogin_user_info}
            display_user_info={display_user_info}
            setDisplay_user_info={setDisplay_user_info}
            Department_msts={Department_msts}
            triggerReloadData={triggerReloadData}
            setTriggerReloadData={setTriggerReloadData}
            />
        </GenericTemplate>
        :
        <SPTemplate 
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
            <RequestKintaiForm 
            login_user_id={login_user_id} 
            Shift_msts={Shift_msts} 
            setKintai_bscs={setKintai_bscs}
            Kintai_bsc={Kintai_bsc} 
            setKintai_bsc={setKintai_bsc}
            Request_overtimes={Request_overtimes}
            setRequest_overtimes={setRequest_overtimes}
            Kintai_outtime={Kintai_outtime}
            setKintai_outtime={setKintai_outtime}
            Request_paid_holiday={Request_paid_holiday}
            setRequest_paid_holiday={setRequest_paid_holiday}
            Login_user_info={Login_user_info}
            setLogin_user_info={setLogin_user_info}
            display_user_info={display_user_info}
            setDisplay_user_info={setDisplay_user_info}
            Department_msts={Department_msts}
            triggerReloadData={triggerReloadData}
            setTriggerReloadData={setTriggerReloadData}
            />
        </SPTemplate>
        }</>
    );
}

export default RequestKintai;