import React from 'react';
import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import * as commonFnc from '../common/commonFnc';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles({
  modalTextField:{
    margin: '5px',
    width: '100%'
  }
});


// name: DepMstDepartmentEditModal
// summary: ユーザ登録・編集用のモーダルフォーム
// prop1: Department_mst モーダルに表示する部署情報
// prop2: setDepartment_mst Department_mstのセッター
// prop3: Department_msts 部署情報
// prop4: depModalopen モーダルの表示・非表示制御フラグ
// prop5: setDepModalopen depModalopenのセッター
const DepMstDepartmentEditModal = (props) => {

  // 01_props
  const{Department_mst, setDepartment_mst, Department_msts, depModalopen, setDepModalopen, beforeDept} = props;

  // 02_useState
  const classes = useStyles()

  // 03_独自関数(eventHandlerなど)
  const handleClose = () => {
    if(commonFnc.areObjectsEqual(beforeDept, Department_mst)){
      setDepModalopen(false);
    }else{
      
      var result = window.confirm('編集中の情報があります。終了しますか？');
      if( !result ) return;
      setDepModalopen(false);
      setDepartment_mst({...Department_mst, department_cd:null});

    }
    
  }
  
  const handleInputChage = (e) =>{
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setDepartment_mst({ ...Department_mst, [name]: value });
  } 

  return (
      <Modal
        open={depModalopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="/api/update/department_mst" method='POST'>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              部署情報
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField label="部署CD" variant="outlined" name="department_cd" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={Department_mst.department_cd} required/>
              <TextField label="名称" variant="outlined" name="name" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={Department_mst.name} required/>
            
              <FormControl className="modalFormControl" required>
                <InputLabel className="InputLabel-style modalInputLabel-style" id="department_type-label">部署タイプ</InputLabel>
                <Select
                labelId='department_type-label'
                variant="outlined"
                name="department_type"
                className='modalSelectField'
                onChange={e => handleInputChage(e)}
                defaultValue={Department_mst.department_type}
                value={Department_mst.department_type}
                >
                  <MenuItem value="1">グループ</MenuItem>
                  <MenuItem value="3">施設</MenuItem>
                  <MenuItem value="5">部署</MenuItem>
              </Select>
            </FormControl>
                <TextField label="一次承認者" variant="outlined" name="first_approval_userid" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={Department_mst.first_approval_userid}/>
                <TextField label="二次承認者" variant="outlined" name="second_approval_userid" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={Department_mst.second_approval_userid}/>
              <div className='modal_float'>
                <TextField label="使用開始日" InputLabelProps={{ shrink: true }} type="date" variant="outlined" name="use_start_day" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={Department_mst.use_start_day}/>
                <TextField label="使用終了日" InputLabelProps={{ shrink: true }} type="date" variant="outlined" name="use_end_day" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={Department_mst.use_end_day}/>
              </div>
              
              <FormControl className="modalFormControl" required>
                <InputLabel className="InputLabel-style modalInputLabel-style" id="highly_department_cd-label">上位部署</InputLabel>
                <Select
                  labelId='highly_department_cd-label'
                  variant="outlined"
                  onChange={e => handleInputChage(e)}
                  name="highly_department_cd"
                  value={Department_mst.highly_department_cd}
                  className='modalSelectField'
                >
                  {Department_msts.map((depMst, index) =>(
                    <MenuItem value={depMst.department_cd} selected={ depMst.department_cd === Department_mst.highly_department_cd }>{depMst.department_cd}  :  {depMst.name}</MenuItem>
                  ))}
                </Select>
                  {/* <FormHelperText>上位の部署を設定します。</FormHelperText> */}
              </FormControl>
              <TextField label="連携コード" variant="outlined" name="if_cd" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={Department_mst.if_cd}/>

            </Typography>
              <Button variant='contained' color='primary' type='submit' className={classes.modalTextField}>登録</Button>
          </Box>
        </form>
      </Modal>
  );
}
export default DepMstDepartmentEditModal;