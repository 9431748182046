import { useContext, useEffect, useState }  from "react";
import ApprovalsHeader from "../conponents/ApprovalsHeader";
import ApprovalsTable from "../conponents/ApprovalsTable";
import GenericTemplate from "../templates/GenericTemplate";
import * as commonFnc from '../common/commonFnc';
import '../css/Loading.css';
import { AppBar, Box, Button, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import { GlobalContext } from "../globalState";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Approvals = (props) =>{
    const {login_user_id,setLogin_user_id, setKintai_bscs, Kintai_bsc, setKintai_bsc, Shift_msts, 
            Department_msts, Users, Login_user_info, setDisplay_user_info,
            setRequest_overtimes, setRequest_paid_holiday, setShiftStartDay} = props;

    const approvalTexts = useContext(GlobalContext).approvalTexts;
    const {serchCondition, setSerchCondition} = useContext(GlobalContext);
    const {filterApprovalStatusCd, setFilterApprovalStatusCd} = useContext(GlobalContext);
    
    const [approval_kintai_bscs, setApproval_kintai_bscs] = useState([]);
    
    const [appcdCounter, setAppcdCounter] = useState({
        count90: 0,
        count80: 0,
        count30: 0,
        count20: 0,
        count10: 0,
    })

    const [value, setValue] = useState(1);
    const {viewIndex, setViewIndex} = useContext(GlobalContext);
    const Env_msts = useContext(GlobalContext).Env_msts;
    let APPROVAL_TABLE_VIEW_NUM = Env_msts.find((p)=>p.env_key === "APPROVAL_TABLE_VIEW_NUM")?.env_value;
    APPROVAL_TABLE_VIEW_NUM = APPROVAL_TABLE_VIEW_NUM?parseInt(APPROVAL_TABLE_VIEW_NUM):30;

    useEffect(async ()=>{
        // 承認待ちの勤怠一覧の取得
        getApproval_kintai_bsc();
        setViewIndex(0);

    },[]);

    useEffect(() => {
      const initialTab = {
        "10": 1,
        "20": 2,
        "30": 3,
        "90": 4,
        "80": 5,
      }[filterApprovalStatusCd] || 1;

      setValue(initialTab);
    }, [filterApprovalStatusCd]);

    const handleChange = (event, newValue) => {

      // BOXが0番目のため1から設定
        if(newValue === 1){
          setFilterApprovalStatusCd("10");
        }else if(newValue === 2){
          setFilterApprovalStatusCd("20");
        }else if(newValue === 3){
          setFilterApprovalStatusCd("30");
        }else if(newValue === 4){
          setFilterApprovalStatusCd("90");
        }else if(newValue === 5){
          setFilterApprovalStatusCd("80");
        }
        setValue(newValue);
        setViewIndex(0);
    };

    const addViewIndex = (num) => {

      const tmpViewIndex = viewIndex + num;
      if(tmpViewIndex<0){
        return;
      }

      if(tmpViewIndex > appcdCounter[`count${filterApprovalStatusCd}`]){
        return;
      }

      setViewIndex(tmpViewIndex);

    }

    
    const [loading, setLoading] = useState(false);
    // 承認待ち情報一覧を取得する。
    const getApproval_kintai_bsc = async () =>{
      setLoading(true);
      const query = new URLSearchParams({
          user_id: login_user_id,
          target_date_from:serchCondition.target_date_from,
          target_date_to:serchCondition.target_date_to
      });
      let res = await fetch(`/api/select/approval_kintai_bsc?${query}`);
      let data = await res.json();
      setApproval_kintai_bscs(data);
      setLoading(false);
      setViewIndex(0);
  }

    return (
        <GenericTemplate title="承認待ち状況"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        {!Login_user_info ? <div class="loader">Loading...</div> :  
            <div>
                <ApprovalsHeader
                  Shift_msts={Shift_msts}
                  Department_msts={Department_msts}
                  serchCondition={serchCondition}
                  setSerchCondition={setSerchCondition}
                  getApproval_kintai_bsc={getApproval_kintai_bsc}
                  loading={loading}
                  setLoading={setLoading}
                  Login_user_info={Login_user_info}
                />
                <AppBar position="static">
                  <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Box
                      sx={{"display":"flex", "padding":"6px 12px"}}
                    >
                      <Button onClick={()=>addViewIndex(-APPROVAL_TABLE_VIEW_NUM)}><ArrowLeftIcon style={{ fontSize: 30, color: 'snow' }} /></Button>
                      <Box sx={{"padding":"6px 12px", "fontSize":"20px", fontWeight:"bold", color:"snow", minWidth:"120px", textAlign:"center"}}>{`${viewIndex} - ${viewIndex+APPROVAL_TABLE_VIEW_NUM}`}</Box>
                      <Button onClick={()=>addViewIndex(APPROVAL_TABLE_VIEW_NUM)}><ArrowRightIcon style={{ fontSize: 30, color: 'snow' }} /></Button>
                    </Box>
                    <Tab label={approvalTexts["10"]+"("+appcdCounter.count10+")"} {...a11yProps(1)} />
                    <Tab label={approvalTexts["20"]+"("+appcdCounter.count20+")"} {...a11yProps(2)} />
                    <Tab label={approvalTexts["30"]+"("+appcdCounter.count30+")"} {...a11yProps(3)} />
                    <Tab label={approvalTexts["90"]+"("+appcdCounter.count90+")"} {...a11yProps(4)} />
                    <Tab label={approvalTexts["80"]+"("+appcdCounter.count80+")"} {...a11yProps(5)} />
                  </Tabs>
                </AppBar>
                  <ApprovalsTable 
                    login_user_id={login_user_id}
                    Kintai_bsc_this={Kintai_bsc}
                    setKintai_bsc={setKintai_bsc} 
                    Shift_msts={Shift_msts} 
                    Department_msts={Department_msts}
                    Users={Users}
                    serchCondition={serchCondition}
                    setSerchCondition={setSerchCondition}
                    Login_user_info={Login_user_info}
                    setDisplay_user_info={setDisplay_user_info}
                    setRequest_overtimes={setRequest_overtimes}
                    setRequest_paid_holiday={setRequest_paid_holiday}
                    setAppcdCounter={setAppcdCounter}
                    filterApprovalStatusCd={filterApprovalStatusCd}
                    viewIndex={viewIndex}
                    APPROVAL_TABLE_VIEW_NUM={APPROVAL_TABLE_VIEW_NUM}
                    approval_kintai_bscs={approval_kintai_bscs}
                    setApproval_kintai_bscs={setApproval_kintai_bscs}
                    getApproval_kintai_bsc={getApproval_kintai_bsc}
                    loading={loading}
                    setLoading={setLoading}
                    setShiftStartDay={setShiftStartDay}
                  />
            </div>
        }
        </GenericTemplate>
    );
}

export default Approvals;