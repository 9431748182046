
import { useContext } from 'react';
import { GlobalContext } from '../globalState'

/**
 * シフトグループコードから加算する勤務日数を取得する。
 * @param {*} shift 
 * @returns 
 */
export function GetWorkDays(shift){

    const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;
    const Env_msts = useContext(GlobalContext).Env_msts;
    const NIGHT_SHIFT_GROUP_CD_COUNT_WORK_DAYS = Env_msts.find((p)=>p.env_key === "NIGHT_SHIFT_GROUP_CD_COUNT_WORK_DAYS")?.env_value;

    let addWork_days = 0;
    if(shift.shift_group_cd === shift_group_cd_hash["FULL_WORK_SHIFT_GROUP_CD"] || shift.shift_group_cd === shift_group_cd_hash["OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]){
        addWork_days = 1 ;
    }else if(shift.shift_group_cd === shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"] || shift.shift_group_cd === shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]){
        addWork_days = 0.5; 
    }else if(shift.shift_group_cd === shift_group_cd_hash["NIGHT_SHIFT_GROUP_CD"]){
        addWork_days = NIGHT_SHIFT_GROUP_CD_COUNT_WORK_DAYS?parseInt(NIGHT_SHIFT_GROUP_CD_COUNT_WORK_DAYS):2 ;
    }
    
    return addWork_days;
    
}



