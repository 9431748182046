import { createContext, useState, useEffect } from "react";
import * as commonFnc from './common/commonFnc';

export const GlobalContext = createContext({});

export const GlobalProvider = (props) => {

    const {
      Login_user_info
  } = props;

    const [approvalTexts, setApprovalTexts] = useState({
        "10": "申請前",
        "20": "一次待ち",
        "30": "二次待ち",
        "80": "差戻し",
        "90": "確定"
      })

    const [shift_type_cd, setShift_type_cd] = useState({
        "PUBLIC_HOLIDAY_CD": "801",
        "PAID_HOLIDAY_CD": "802",
        "HARF_PUBLIC_HOLIDAY_CD": "851",
        "HARF_PAID_HOLIDAY_CD": "852",
        "AKE_CD": "551",
    })

    const [shift_group_cd, setShift_group_cd] = useState({
        "FULL_WORK_SHIFT_GROUP_CD": "10", // 1日勤務
        "HARF_WORK_SHIFT_GROUP_CD": "20", // 半日勤務
        "HOLIDAY_SHIFT_GROUP_CD": "30", // 休日シフト
        "HARF_HOLIDAY_SHIFT_GROUP_CD": "40", // 半日休日シフト
        "TIME_PAID_HOLIDAY_SHIFT_GROUP_CD": "45", // 時間有給シフト
        "NIGHT_SHIFT_GROUP_CD": "50", // 夜勤
        "NIGHT_WATCH_SHIFT_GROUP_CD": "60", // 宿直
        "OVER_NIGHT_WATCH_SHIFT_GROUP_CD": "61", // 宿日直
        "HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD": "62", // 半宿日直
        "SICK_LEAVE_GROUP_CD": "90", // 病欠
        "HARF_SICK_LEAVE_GROUP_CD": "91" // 半日病欠
        })

    const [shiftGroupInfo, setShiftGroupInfo] = useState({
      "10":{
        name:"一日勤務",
        note:"1日(8時間)勤務するシフト",
      },
      "20":{
        name:"半日勤務",
        note:"半日(AM/PMのみ)勤務するシフト",
      },
      "30":{
        name:"一日休暇",
        note:"1日(8時間)休みのシフト",
      },
      "40":{
        name:"半日休暇",
        note:"半日休みのシフト：「半日休暇」申請時に選択する。",
      },
      "45":{
        name:"時間有給",
        note:"時間有給のシフト：「時間有給」申請時に選択する。",
      },
      "50":{
        name:"夜勤",
        note:"夜勤シフト：勤務時間は16時間換算",
      },
      "60":{
        name:"宿直",
        note:"宿直のみのシフト：勤務時間は0時間換算。宿直内で勤務した時間は残業時間扱い。",
      },
      "61":{
        name:"宿日直",
        note:"宿直+1日勤務のシフト：宿直の前後で1日勤務がある場合に選択する。",
      },
      "62":{
        name:"半日宿日直",
        note:"宿直+半日勤務のシフト：宿直の前後で半日勤務がある場合に選択する。",
      },
      "90":{
        name:"欠勤",
        note:"1日(8時間)欠勤するシフト",
      },
      "91":{
        name:"半日欠勤",
        note:"半日(AM/PM)欠勤のシフト：「時間休暇」申請時に選択する。",
      },
      "99":{
        name:"その他",
        note:"その他",
      }})
    

    // 環境変数
    const [Env_msts, setEnv_msts] = useState([]);
    useEffect( ()=>{
        fetch(`/api/select/env_mst`)
        .then((res) => res.json())
        .then((data) => setEnv_msts(data));
    },[Login_user_info]);

    const [limits, setSimits] = useState({
        "LIMIT_OVER_TIME": 10*60*60*1000
        })

    const [scrollInfomationList, setScrollInfomationList] = useState([]);
    useEffect( ()=>{
        fetch(`/api/select/information_from_enterprise`)
        .then((res) => res.json())
        .then((data) => setScrollInfomationList(data));
    },[Login_user_info]);

    // 承認画面の検索条件
    let tmp_start_day = commonFnc.getShiftStartDay(new Date());
    let tmp_end_day = new Date(tmp_start_day.getFullYear(), tmp_start_day.getMonth()+1, 0);
    let target_date_from_str = commonFnc.formatDate(tmp_start_day);
    let target_date_to_str = commonFnc.formatDate(tmp_end_day);

    const [serchCondition, setSerchCondition] = useState({
      target_date_from : target_date_from_str,
      target_date_to : target_date_to_str,
      target_deptgr_cd : "",
      target_dept_cd : "",
      target_user_id : "",
      target_shift_type_cd : "",
      target_user_name : "",
      exist_over_time : 0,
    });

    const [filterApprovalStatusCd, setFilterApprovalStatusCd] = useState("10");
    const [viewIndex, setViewIndex] = useState(0);

  return(
    <GlobalContext.Provider value={{
      approvalTexts, setApprovalTexts,
      shift_type_cd, setShift_type_cd,
      shift_group_cd, setShift_group_cd,
      shiftGroupInfo, setShiftGroupInfo,
      Env_msts, setEnv_msts, 
      limits, setSimits,
      scrollInfomationList, setScrollInfomationList,
      serchCondition, setSerchCondition,
      filterApprovalStatusCd, setFilterApprovalStatusCd,
      viewIndex, setViewIndex,
      }}>
      {props.children}
    </GlobalContext.Provider>
  )
}