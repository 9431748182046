
import * as commonFnc from '../common/commonFnc';

/**
 * ユーザ情報
 * @type {Kintai_overtime}
 */
export class Kintai_overtime {

    constructor(Kintai_bsc){
        
        this.user_id=Kintai_bsc.user_id;
        this.date=commonFnc.formatDate(Kintai_bsc.date);
        this.over_time_from="";
        this.over_time_to="";
        this.reason="";
        this.seqno=0;
        this.approval_status_cd="10";
    
    }

}