import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import * as BL_requestKintai from '../BL/BL_requestKintai_tmp';

const SP_Kintai_SelectBox = (props) => {
  const {
    kintaiInfo,
    setKintaiInfo,
    target_name, 
    label_name, 
    disabled_flg, 
    Shift_msts, 
    shift_list, 
    // Login_user_info, 
    // setLogin_user_info,
    setKintaiInfoPublicHolidayTime,
    setKintaiInfoPaidHolidayTime,
  } = props;

  
  const [beforeShiftTypeCd, setBeforeShiftTypeCd] = useState(kintaiInfo[target_name]);
  
  useEffect(()=>{
      
    const publicHolidayTime = BL_requestKintai.getHolidayTime(kintaiInfo.shift_type_cd, Shift_msts, "public_holiday_time") 
      + BL_requestKintai.getHolidayTime(kintaiInfo.harf_holiday_shift_type_cd, Shift_msts, "public_holiday_time") 
      + BL_requestKintai.getHolidayTime(kintaiInfo.paid_holiday_shift_type_cd, Shift_msts, "public_holiday_time");

    const paidHolidayTime = BL_requestKintai.getHolidayTime(kintaiInfo.shift_type_cd, Shift_msts, "paid_holiday_time") 
      + BL_requestKintai.getHolidayTime(kintaiInfo.harf_holiday_shift_type_cd, Shift_msts, "paid_holiday_time") 
      + BL_requestKintai.getHolidayTime(kintaiInfo.paid_holiday_shift_type_cd, Shift_msts, "paid_holiday_time");


      setKintaiInfoPublicHolidayTime(publicHolidayTime);
      setKintaiInfoPaidHolidayTime(paidHolidayTime);

  },[kintaiInfo])


  /**
   * シフト変更
   * @param {*} e イベント
   * @param {*} beforeShiftTypeCd 変更前のシフトタイプCd
   * @param {*} setBeforeShiftTypecd 変更前のシフトタイプCdのセッター
   */
  const handleSelectShiftType = (e, beforeShiftTypeCd, setBeforeShiftTypecd) =>{

      const target = e.target;
      const value = target.value;
      const name = target.name;

      // 勤怠情報更新
      setKintaiInfo({
          ...kintaiInfo,
          [name]: value,
      });

  }

  return (
    <div>
      <TextField
          select
          id={"select_" + target_name}
          label={label_name}
          variant="outlined"
          name={target_name} 
          value={kintaiInfo[target_name]}
          onChange={(e)=>handleSelectShiftType(e, beforeShiftTypeCd, setBeforeShiftTypeCd)}
          className="clockin_select select_tworow"
          disabled={disabled_flg}
      >
      <MenuItem
        key="-1"
        value={null}
        className="clockin_menuitem">
      </MenuItem>
        {shift_list.map((shift_mst, index)=>(
          <MenuItem
            key={index}
            value={shift_mst.shift_type_cd}
            className="clockin_menuitem">
              {shift_mst.shift_name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
export default SP_Kintai_SelectBox;