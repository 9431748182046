import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ApprovalsTableKntaiReturnModal from "./ApprovalsTableKntaiReturnModal";
import KintaiHolidayShiftEntryModal from "./KintaiHolidayShiftEntryModal";
import * as BL_kintai from '../BL/BL_kintai';
import * as BL_approval from '../BL/BL_approval';
import * as commonFnc from '../common/commonFnc';
import {GlobalContext} from '../globalState'
import '../css/Loading.css';
import { Kintai_overtime } from "../classes/Kintai_overtime_class";



// name: KintaiTable
// summary: 【申請者画面】勤怠を入力する。
// prop1: Department_mst モーダルに表示する部署情報
// prop2: setDepartment_mst Department_mstのセッター
// prop3: Department_msts 部署情報
// prop4: depModalopen モーダルの表示・非表示制御フラグ
// prop5: setDepModalopen depModalopenのセッター
const KintaiTable = (props) =>{
    
    // 01_props
    const {
        setLogin_user_info,
        login_user_id, 
        setKintai_bscs, 
        Kintai_bscs, 
        Kintai_bsc, 
        setKintai_bsc, 
        Shift_msts, 
        shiftStartDay, 
        Department_msts, 
        setRequest_overtimes,
        setRequest_paid_holiday, 
        display_user_info,
        setDisplay_user_info, 
        Users
    } = props;
    
    // 02_useState

    const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;

    const Env_msts = useContext(GlobalContext).Env_msts;
    const count1 = Env_msts.find(p=>p.env_key==="COUNT1");
    const count2 = Env_msts.find(p=>p.env_key==="COUNT2");
    const count3 = Env_msts.find(p=>p.env_key==="COUNT3");
    const count4 = Env_msts.find(p=>p.env_key==="COUNT4");
    const count5 = Env_msts.find(p=>p.env_key==="COUNT5");

    const [clockin_jarnals_atMonth_atUser, setClockin_jarnals_atMonth_atUser] = useState([]);

    const [Kintai_bscs_view, setKintai_bscs_view] = useState([]);

    const [holidays, setHolidays] = useState({})


    useEffect(()=>{

        fetch('https://holidays-jp.github.io/api/v1/date.json')
        .then((res) => res.json())
        .then((data)=>setHolidays(data));

    },[])

    useEffect(async ()=>{
        var tmpDay = new Date(shiftStartDay);
        let shiftEndDay = tmpDay.setMonth(tmpDay.getMonth() + 1);

        const query_clockin = new URLSearchParams({target_user_id: display_user_info?.user_id, target_date_from: commonFnc.formatDate(shiftStartDay), target_date_to:commonFnc.formatDate(shiftEndDay)});
        let res_clockin = await fetch(`/api/select/clockin_jarnals_atMonth_atUser?${query_clockin}`);
        let data_clockin = await res_clockin.json();
        setClockin_jarnals_atMonth_atUser(data_clockin);

        getKintaiBscs();

    },[shiftStartDay, display_user_info])

    useEffect(()=>{

        var days = commonFnc.getCalendar(new Date(shiftStartDay));
        setKintai_bscs_view(commonFnc.createCalender(days, Kintai_bscs, clockin_jarnals_atMonth_atUser, display_user_info?.user_id));

    },[clockin_jarnals_atMonth_atUser,Kintai_bscs])

    const history = useHistory();

    var shiftHash = {};
    Shift_msts.map((Shift_mst) => (
        shiftHash[Shift_mst.shift_type_cd] = Shift_mst.shift_name
    ));

    var shiftColorHash = {};
    Shift_msts.map((Shift_mst) => (
        shiftColorHash[Shift_mst.shift_type_cd] = {
            backgroundcolor: Shift_mst.backgroundcolor,
            textcolor: Shift_mst.textcolor
        }
    ));
    
    const approvalTexts = useContext(GlobalContext).approvalTexts;
    const shiftGroupCdHash = useContext(GlobalContext).shift_group_cd;

    const [approvalReturnModalOpen, setApprovalReturnModalOpen] = useState(false);
    const [holidayShiftEntryModalOpen, setHolidayShiftEntryModalOpen] = useState(false);
    const [harfWorkFlg, setHarfWorkFlg] = useState(false);
    
   
    // 03_独自関数(eventHandlerなど)
    const getKintaiBscs = async () =>{
        if(display_user_info){
            const query = new URLSearchParams({login_user_id: display_user_info.user_id});
            const res = await fetch(`/api/select/kintai_bsc?${query}`);
            const data = await res.json();
            setKintai_bscs(data);
        }
    }

    
    const moveRequestKintaiPage = ((Kintai_bsc) =>{
        setKintai_bsc(Kintai_bsc);
        // setKintai_bsc({...Kintai_bsc,harf_holiday_shift_type_cd:null});
        localStorage.setItem('mykintai_bsc',JSON.stringify(Kintai_bsc))

        // fechで取得するように変更する
        const query = new URLSearchParams({login_user_id: Kintai_bsc.user_id, date: commonFnc.formatDate(Kintai_bsc.date)});
        fetch(`/api/select/request_overtime?${query}`)
        .then((res) => res.json())
        .then((data) => {
            if(!data || data.length === 0){
                setRequest_overtimes([new Kintai_overtime(Kintai_bsc)])
            }else{
                setRequest_overtimes(data)
            }
        });

        const queryRequest_paid_holiday = new URLSearchParams({login_user_id: Kintai_bsc.user_id, date: commonFnc.formatDate(Kintai_bsc.date)});
        fetch(`/api/select/request_paid_holiday?${queryRequest_paid_holiday}`)
        .then((res) => res.json())
        .then((data) => {
            if(!data){
                setRequest_paid_holiday({
                    request_paid_holiday_reason: "",
                })
            }else{
                setRequest_paid_holiday(data)
            }
        });

        let tmp_user = Users.find(item => item.user_id === display_user_info.user_id);
        setDisplay_user_info(tmp_user);

        history.push('/RequestKintai');
    })

    // 申請ボタンクリック
    const handlerRequestButtonClick = ((target_kintai_bsc) =>{
        const target_shift = Shift_msts.find((p)=>p.shift_type_cd === target_kintai_bsc?.shift_type_cd);
        if(target_kintai_bsc.approval_status_cd === "10" || target_kintai_bsc.approval_status_cd === "80"){
            if(!target_kintai_bsc.shift_type_cd || (target_shift?.shift_group_cd === shiftGroupCdHash["HARF_WORK_SHIFT_GROUP_CD"] && !target_kintai_bsc.harf_holiday_shift_type_cd)){
                alert("半休シフトが選択されていません。");
                return;
            }
            BL_kintai.requestApproval(target_kintai_bsc, Department_msts, setKintai_bscs);
        }
        
        if(target_kintai_bsc.approval_status_cd === "20"){
            BL_kintai.requestCancel(target_kintai_bsc, Department_msts, setKintai_bscs);
        }
    });
    
    // 承認ボタンクリック
    const handlerApprovalButtonClick = async (target_kintai_bsc) =>{
        await BL_approval.requestApproval("kintai_bsc", target_kintai_bsc, Department_msts, setKintai_bscs, login_user_id);
        getKintaiBscs();
    };

    // 差戻ボタンクリック
    const handlerReturnButtonClick = async(target_kintai_bsc) =>{
        await setKintai_bsc(target_kintai_bsc);
        setApprovalReturnModalOpen(true);
    };

    // 取り消しボタンクリック
    const handlerCancelButtonClick = async (target_kintai_bsc) =>{
        await BL_approval.requestCancel("kintai_bsc", target_kintai_bsc, Department_msts, setKintai_bscs, login_user_id);
        getKintaiBscs();
    };

    
    
    // 進捗管理用
    const [progress, setProgress] = useState(0);
    const [allNum, setAllNum] = useState(0);
    const progressInitial = (allNum) =>{
        setAllNum(allNum);
    }
    const progressCountUp = (completedNum) =>{
        setProgress(completedNum);
    }
    const progressClear = (completedNum) =>{
        setProgress(0);
        setAllNum(0)
    }

    // 一括承認ボタンクリック
    const handlerAllRequestButtonClick = async () =>{

        var result = window.confirm('一括で承認しますか。');
        if( result ) { 
            
            // 進捗管理を開始
            let completed = 1;
            progressInitial(Kintai_bscs_view.length);

            const promises = Kintai_bscs_view.map(async (target_kintai_bsc) => {
                if(target_kintai_bsc.next_approval_userid === login_user_id){
                    await BL_approval.requestApproval("kintai_bsc", target_kintai_bsc, Department_msts, setKintai_bscs, login_user_id);
                    progressCountUp(completed += 1);
                }
            });
            await Promise.all(promises);
            getKintaiBscs();
            window.alert("一括承認が完了しました。");
            progressClear();

        }
    };
    
    // 休みシフト登録ボタンクリック
    const handleHolidayShiftEntryButtonClick = ((target_kintai_bsc) =>{
        const tmp_shift_mst =  Shift_msts.find(p=>p.shift_type_cd === target_kintai_bsc?.shift_type_cd); 
        
        // user_idが取得可能かつ本人で無ければ終了
        if(display_user_info.user_id !== login_user_id){
            return;
        }

        // シフト情報が存在しない、申請済み、差戻し以外は終了
        if(!(!target_kintai_bsc.approval_status_cd || target_kintai_bsc.approval_status_cd === "10" || target_kintai_bsc.approval_status_cd === "80")){
            return;
        }
        
        // シフトが登録済みだけど、そのシフトが半日シフトで無ければ終了。
        // 半日シフトでもすでに半休が登録されていれば終了
        // if(tmp_shift_mst && tmp_shift_mst.shift_group_cd !== shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"]){
        //     return;   
        // }else if(target_kintai_bsc.harf_holiday_shift_type_cd){
        //     return;   
        // }

        // シフトが登録済みなら終了
        if(tmp_shift_mst){
            return;   
        }
        


        setHarfWorkFlg(tmp_shift_mst?.shift_group_cd === shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"]);

        setKintai_bsc(target_kintai_bsc);
        setHolidayShiftEntryModalOpen(true);
    });

    return (
        <div className={progress?"loading-wrapper":false}>
            {progress?
            <div class="loading-center">
                <div class="loader">Loading...</div>
                <div>処理済み: {`${progress}/${allNum}`}</div>
            </div>:<></>
            }
            <TableContainer component={Paper}>
            {!Kintai_bscs_view || Kintai_bscs_view.length === 0 || !holidays? <div class="loader">Loading...</div> :
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">日付</TableCell>
                            <TableCell align="center">シフト</TableCell>
                            <TableCell align="center">開始時間</TableCell>
                            <TableCell align="center">終了時間</TableCell>
                            <TableCell align="center">時間</TableCell>
                            <TableCell align="center">休憩</TableCell>
                            <TableCell align="center">時間外</TableCell>
                            <TableCell align="center">深夜</TableCell>
                            <TableCell align="center">遅刻</TableCell>
                            <TableCell align="center">早退</TableCell>
                            <TableCell align="center">外出</TableCell>
                            <TableCell align="center">業務外</TableCell>
                            {count1?.log_del_flg ? "" : <TableCell align="center">{count1?.env_value.substr( 0, 4 )}</TableCell>}
                            {count2?.log_del_flg ? "" : <TableCell align="center">{count2?.env_value.substr( 0, 4 )}</TableCell>}
                            {count3?.log_del_flg ? "" : <TableCell align="center">{count3?.env_value.substr( 0, 4 )}</TableCell>}
                            {count4?.log_del_flg ? "" : <TableCell align="center">{count4?.env_value.substr( 0, 4 )}</TableCell>}
                            {count5?.log_del_flg ? "" : <TableCell align="center">{count5?.env_value.substr( 0, 4 )}</TableCell>}
                            <TableCell align="center">申請状況</TableCell>
                            <TableCell align="center" >
                                <div hidden={display_user_info?.user_id === login_user_id}>
                                    <Button variant="outlined" color="primary" onClick={()=>handlerAllRequestButtonClick()}>
                                        一括
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Kintai_bscs_view.map((Kintai_bsc, index)=>(
                            <TableRow key={index} style={{backgroundColor:commonFnc.getDayofWeekColor(Kintai_bsc.date, holidays)}}>
                                <TableCell component="th" scope="row" align="center">
                                    <Button variant="outlined" color="primary"
                                        onClick={(e)=>moveRequestKintaiPage(Kintai_bsc)}
                                        value={commonFnc.ConvertJST(Kintai_bsc.date).split(' ')[0]}
                                        style={{backgroundColor:"white", width:130}}>
                                        {commonFnc.ConvertJST(Kintai_bsc.date).split(' ')[0]
                                        + "(" + [ "日", "月", "火", "水", "木", "金", "土" ][Kintai_bsc.date.getDay()] + ")"}
                                    </Button>
                                </TableCell>
                                <TableCell align="center">
                                    <div 
                                        className={"shift_type"} 
                                        style={{backgroundColor: Kintai_bsc.shift_type_cd ? shiftColorHash[Kintai_bsc.shift_type_cd]?.backgroundcolor:"transparent" ,color: Kintai_bsc.shift_type_cd ? shiftColorHash[Kintai_bsc.shift_type_cd]?.textcolor:""}}
                                        onClick={()=>handleHolidayShiftEntryButtonClick(Kintai_bsc)}
                                        >
                                        {shiftHash[Kintai_bsc.shift_type_cd]?shiftHash[Kintai_bsc.shift_type_cd]:"-"}
                                    </div>
                                    {Kintai_bsc.harf_holiday_shift_type_cd?
                                    <div className={"shift_type"} style={{backgroundColor: Kintai_bsc.harf_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.harf_holiday_shift_type_cd]?.backgroundcolor:"" ,color: Kintai_bsc.harf_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.harf_holiday_shift_type_cd]?.textcolor:""}}>
                                        {shiftHash[Kintai_bsc.harf_holiday_shift_type_cd]}
                                    </div>
                                    :""}
                                    {Kintai_bsc.paid_holiday_shift_type_cd?
                                    <div className={"shift_type"} style={{backgroundColor: Kintai_bsc.paid_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.paid_holiday_shift_type_cd]?.backgroundcolor:"" ,color: Kintai_bsc.paid_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.paid_holiday_shift_type_cd]?.textcolor:""}}>
                                        {shiftHash[Kintai_bsc.paid_holiday_shift_type_cd]}
                                    </div>
                                    :""}
                                </TableCell>
                                <TableCell align="center">
                                    <span>{commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_from)}</span>
                                    <span class="helper_clockin_time"><br/>{(!Kintai_bsc.clockin_time_from ? "" : Kintai_bsc.clockin_time_from)}</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span>{commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_to)}</span>
                                    <span class="helper_clockin_time"><br/>{(!Kintai_bsc.clockin_time_to ? "" : Kintai_bsc.clockin_time_to)}</span>
                                </TableCell>
                                <TableCell align="center">{!Kintai_bsc.work_time ? "" : commonFnc.getHHmm(Kintai_bsc.work_time)}</TableCell>
                                <TableCell align="center">{!Kintai_bsc.rest_time ? "" : commonFnc.getHHmm(Kintai_bsc.rest_time)}</TableCell>
                                <TableCell align="center">{!Kintai_bsc.over_time ? "" : commonFnc.getHHmm(Kintai_bsc.over_time)}</TableCell>
                                <TableCell align="center">{!Kintai_bsc.midnight_over_time ? "" : commonFnc.getHHmm(Kintai_bsc.midnight_over_time)}</TableCell>
                                <TableCell align="center">{!Kintai_bsc.behind_time ? "" : commonFnc.getHHmm(Kintai_bsc.behind_time)}</TableCell>
                                <TableCell align="center">{!Kintai_bsc.early_time ? "" : commonFnc.getHHmm(Kintai_bsc.early_time)}</TableCell>
                                <TableCell align="center">{!Kintai_bsc.out_time ? "" : commonFnc.getHHmm(Kintai_bsc.out_time)}</TableCell>
                                <TableCell align="center">{!Kintai_bsc.training_time ? "" : commonFnc.getHHmm(Kintai_bsc.training_time)}</TableCell>
                                {count1?.log_del_flg ? "" : <TableCell align="center">{Kintai_bsc.count1 ? count1?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count2?.log_del_flg ? "" : <TableCell align="center">{Kintai_bsc.count2 ? count2?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count3?.log_del_flg ? "" : <TableCell align="center">{Kintai_bsc.count3 ? count3?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count4?.log_del_flg ? "" : <TableCell align="center">{Kintai_bsc.count4 ? count4?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count5?.log_del_flg ? "" : <TableCell align="center">{Kintai_bsc.count5 ? count5?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                <TableCell align="center"><div className={`approval_status approval_status_color${Kintai_bsc.approval_status_cd}`}>{approvalTexts[Kintai_bsc.approval_status_cd]}</div></TableCell>
                                {Kintai_bsc.user_id === login_user_id || Kintai_bsc.user_id === undefined?
                                <TableCell align="center">
                                    <Button variant="outlined" color="primary"
                                        disabled={Kintai_bsc.approval_status_cd !== "10" && Kintai_bsc.approval_status_cd !== "20" && Kintai_bsc.approval_status_cd !== "80"}
                                        onClick={(e)=>handlerRequestButtonClick(Kintai_bsc)}
                                        style={{backgroundColor:"white"}}>
                                        {(Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "80" )? "申請" : "取消"}
                                    </Button>
                                </TableCell>
                                : Kintai_bsc.last_approval_userid === login_user_id ?
                                    <TableCell align="center">
                                        <Button variant="outlined" color="primary" 
                                            onClick={(e)=>handlerCancelButtonClick(Kintai_bsc)}>
                                            取消
                                        </Button>
                                    </TableCell>
                                    : 
                                    <TableCell align="center">
                                        <div>
                                            <Button variant="outlined" color="primary" 
                                                disabled={Kintai_bsc.next_approval_userid !== login_user_id}
                                                onClick={(e)=>handlerApprovalButtonClick(Kintai_bsc)}>
                                                承認
                                            </Button>
                                        </div>
                                        <div>
                                            <Button variant="outlined" color="primary" 
                                                disabled={Kintai_bsc.next_approval_userid !== login_user_id}
                                                onClick={(e)=>handlerReturnButtonClick(Kintai_bsc)}>
                                                差戻
                                            </Button>
                                        </div>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                        <ApprovalsTableKntaiReturnModal
                            Kintai_bsc={Kintai_bsc}
                            setKintai_bsc={setKintai_bsc}
                            approvalReturnModalOpen={approvalReturnModalOpen}
                            setApprovalReturnModalOpen={setApprovalReturnModalOpen}
                            Department_msts={Department_msts}
                            setApproval_kintai_bscs={setKintai_bscs}
                            login_user_id={login_user_id}
                            urlPrefix="kintai_bsc"
                            getApproval_kintai_bsc={getKintaiBscs}
                        >
                
                        </ApprovalsTableKntaiReturnModal>
                                
                        <KintaiHolidayShiftEntryModal
                            setLogin_user_info={setLogin_user_info}
                            display_user_info={display_user_info}
                            setDisplay_user_info={setDisplay_user_info}
                            isModalOpen={holidayShiftEntryModalOpen}
                            setIsModalOpen={setHolidayShiftEntryModalOpen}
                            Shift_msts={Shift_msts}
                            Kintai_bsc={Kintai_bsc}  
                            setKintai_bsc={setKintai_bsc}
                            setKintai_bscs={setKintai_bscs}
                            harfWorkFlg={harfWorkFlg}
                            
                        >

                        </KintaiHolidayShiftEntryModal>
                    </TableBody>
                </Table>
            }
            </TableContainer>
        </div>
    );
}
export default KintaiTable;