import { Box, Button, FormControlLabel, Switch } from '@material-ui/core';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useContext } from "react";
import {GlobalContext} from '../globalState'
import _ from 'lodash';
import { set } from 'mongoose';


const DepMstMenberGrid = (props) => {
  const {
    Users, 
    setUsers, 
    Department_msts,
    public_holiday_type_msts,
    handleEditUserButton,
    setUser,
    handleNewUserButton
  } = props;

  const [isGetRetireMembers, setIsGetRetireMembers] = useState(false);

  // 部署情報
  var deptHash = {};
  Department_msts.map((department) => (
    deptHash[department.department_cd] = department.name
  ));

  // 公休タイプ情報
  var publicHolidayTypeHash = {};
  public_holiday_type_msts.map((pulicHolidayType)=>(
    publicHolidayTypeHash[pulicHolidayType.public_holiday_type_cd] = pulicHolidayType.type_name
  ));

  // 権限情報
  var authorityHash = {
    10:"一般ユーザ",
    20:"管理者",
    90:"システム管理者",
  };

  // 雇用形態情報
  var employmentStatusHash = {
    "1":"正職員",
    "2":"フルタイムパート",
    "3":"パート",
    "4":"Wワーク",
  };

  // 勤務形態情報
  var workStyleHash = {
    "101":"週5日以上",
    "201":"週4日",
    "202":"週3日",
    "203":"週2日",
    "204":"週1日",
  };

  // 論理削除情報
  var logdelHash = {
    true:"無効",
    false:"有効",
  };
  

  const columns = [
    {field:'user_id', headerName:'ユーザID', align:"center", headerAlign:"center"},
    {field:'first_name', headerName:'漢字氏', align:"center", headerAlign:"center"},
    {field:'second_name', headerName:'漢字名', align:"center", headerAlign:"center"},
    {field:'authority', headerName:'権限',valueGetter: (params) => authorityHash[params.row.authority] || '不明', align:"center", headerAlign:"center"},
    {field:'entrer_date', headerName:'入職日', align:"center", headerAlign:"center"},
    {field:'start_paid_holiday_add_day', headerName:'有給起算日', align:"center", headerAlign:"center"},
    {field:'retire_date', headerName:'退職日', align:"center", headerAlign:"center"},
    {field:'log_del_flg', headerName:'有効無効',valueGetter: (params) => logdelHash[params.row.log_del_flg] || '不明', align:"center", headerAlign:"center"},
    {field:'organization_cd', headerName:'所属組織',valueGetter: (params) => deptHash[params.row.organization_cd] || '不明', width: 150 , align:"center", headerAlign:"center"},
    {field:'department_cd', headerName:'所属部署',valueGetter: (params) => deptHash[params.row.department_cd] || '不明', width: 100 , align:"center", headerAlign:"center"},
    {field:'employment_status_cd', headerName:'雇用形態',valueGetter: (params) => employmentStatusHash[params.row.employment_status_cd] || '不明', align:"center", headerAlign:"center"},
    {field:'work_style_cd', headerName:'勤務形態',valueGetter: (params) => workStyleHash[params.row.work_style_cd] || '不明', align:"center", headerAlign:"center"},
    {field:'public_holiday_type_cd', headerName:'公休タイプ',valueGetter: (params) => publicHolidayTypeHash[params.row.public_holiday_type_cd] || '不明', align:"center", headerAlign:"center"},
    {field:'first_approval_userid', headerName:'一次承認者', align:"center", headerAlign:"center"},
    {field:'second_approval_userid', headerName:'二次承認者', align:"center", headerAlign:"center"},
    {field:'enable_public_holiday_days', headerName:'公休残数', align:"right", headerAlign:"center"},
    {field:'enable_paid_holiday_days', headerName:'有給残数', align:"right", headerAlign:"center"},
    {field:'fixed_overtime', headerName:'みなし残業時間', align:"center", headerAlign:"center"},
    // {field:'if_cd', headerName:'連携コード'},
    {field:'notes', headerName:'備考', width: 300 },
    {field:'idm', headerName:'idm', width: 150 , align:"center", headerAlign:"center"}
  ]


  const getRowClassName = (params) => {
    if (new Date(params.row.retire_date?params.row.retire_date:"2200/12/31").getTime() < new Date().getTime()) {
      return 'del'; // カスタムクラス名を返す
    }
    return ''; // デフォルトのスタイル
  };

  return (
    <Box>
      <Box sx={{marginBottom:'10px'}}>
        <Button 
          variant='contained' 
          style={{marginRight : '10px'}}
          onClick={handleNewUserButton}
        >+</Button>
        <FormControlLabel
            control={<Switch checked={isGetRetireMembers} onChange={()=>setIsGetRetireMembers(!isGetRetireMembers)} name="date" />}
            label="退職者も表示"
        />
      </Box>
        <Box style={{ width: '100%' }}>
        <DataGrid
            rows={isGetRetireMembers ? Users.filter((p)=>p.authority!==90) : Users.filter((p)=>p.authority!==90).filter((p)=>new Date(p.retire_date?p.retire_date:"2200/12/31").getTime() > new Date().getTime())}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            autoHeight={true}
            disableSelectionOnClick
            density="compact"
            getRowId={(Users) => Users.user_id}
            components={{
              Toolbar: GridToolbar
            }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            // onRowClick={(params)=>setUser(params.row)}
            onRowDoubleClick={(params)=>handleEditUserButton(params.row)}
            getRowClassName={getRowClassName}
        />
      </Box>
    </Box>
  );
}
export default DepMstMenberGrid;