import { Button } from '@material-ui/core';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useState } from 'react';
import { useContext } from "react";
import {GlobalContext} from '../globalState'
import _ from 'lodash';


const EnvMstEdit = (props) => {
  const {} = props;
  const Env_msts = useContext(GlobalContext).Env_msts;
  const setEnv_msts = useContext(GlobalContext).setEnv_msts;

  const [selectionModel, setSelectionModel] = useState([]);

  const columns = [
    {field: 'env_key', headerName: 'キー', width: 200},
    {field: 'env_bool', headerName: '値_bool', width: 90, editable: true },
    {field: 'env_value', headerName: '値_value', width: 150, editable: true },
    {field: 'env_note', headerName: '備考', width: 500, editable: true },
    {field: 'log_del_flg', headerName: '無効フラグ', width: 90, editable: true }
  ]


    // セルの更新
  const changeCell = (v) => {
    // console.log(v.id)
    let newValue = _.cloneDeep(Env_msts); /* lodashでstateをディープコピー */
    let idx = newValue.findIndex((d) => d.env_key == v.id);  /* 該当データのindexを取得 */
    // console.log(idx)
    newValue[idx][v.field] = v.value;
    setEnv_msts(newValue)  /* 編集されたデータを置き換える */
  }

  const handleConfirmButton = async () => {
    var url_kintai = "/api/update/env_mst";
    const requestOptions_kintai ={
        method: 'POST',
        headers:{'Accept': 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify({env_body: Env_msts})
    };
    await fetch(url_kintai,requestOptions_kintai);

    const res = await fetch(`/api/select/env_mst`)
    const data = await res.json();
    setEnv_msts(data);
    alert("データを更新しました。");
    

  }

  return (
    <div style={{ width: '100%' }}>
        <DataGrid
            rows={Env_msts}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            autoHeight={true}
            checkboxSelection
            disableSelectionOnClick
            getRowId={(Env_msts) => Env_msts.env_key}
            components={{
              Toolbar: GridToolbar
            }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onSelectionModelChange={(RowId) => {
              const selectedRowId = new Set(RowId);
              const selectedRows = Env_msts.filter((dataGridRow) =>
                selectedRowId.has(dataGridRow.env_key)
              );
              setSelectionModel(selectedRows);
            }}
            onCellEditCommit={(e)=>changeCell(e)}
        />
        <Button className={"acction_button"} onClick={()=>handleConfirmButton()}>更新</Button>
    </div>
  );
}
export default EnvMstEdit;