import * as React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { createTheme, ThemeProvider  } from "@material-ui/core/styles";
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as commonFnc from '../common/commonFnc';
import {GlobalContext} from '../globalState'

function Copyright(props) {
  return (
    <Typography variant="body2" color="secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={commonFnc.isMobile()?"":"/ClockinPage"} >
        tk3stars.inc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}            

const theme = createTheme();

const SignIn = (props) =>{
  const {APP_KEY, setLogin_user_id} = props;
  const Env_msts = useContext(GlobalContext).Env_msts;
  const MAMERECO_VERSION = Env_msts.find(p=>p.env_key === "MAMERECO_VERSION")?.env_value;

  
  const [input_user_id, setInput_user_id] = useState("")
  const [input_pw, setInput_pw] = useState("")

  const history = useHistory();

  // IDPW認証
const handleSubmit = async (event) => {
  event.preventDefault();

  const query = new URLSearchParams({user_id: input_user_id, pw: input_pw});

  try {
    const response = await fetch(`/api/select/idpw?${query}`);

    // レスポンスが成功かどうかを確認
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || '予期せぬエラー');
    }


    const data = await response.json();

    if (!data) {
      throw new Error('予期せぬエラー');
    } else if(data.log_del_flg === true){
      alert("ログインが禁止されています。管理者に連絡してください。");
    }else {
      localStorage.setItem(APP_KEY, input_user_id);
      setLogin_user_id(input_user_id);

      if (commonFnc.isMobile()) {
        history.push('/sp_kintaiListPage');
      } else {
        const internalAccess = await commonFnc.isInternalAccess();

        if (internalAccess) {
          commonFnc.insertUserLog("INFO", input_user_id, __filename,  "ログインページ", "ログイン", "画面遷移", "", null, null );
          history.push('/Kintai');
        } else {
          alert("社外からのアクセスは不正です。");
        }
      }
    }
  } catch (error) {
    alert(error.response?.data?.message || 'ログインに失敗しました。');
  }
}

  const handleInputChage = (e) =>{
      const target = e.target;
      const value = target.value;
      const name = target.name;
      if(name === "user_id"){
        setInput_user_id(value);
      }else if(name === "password"){
        setInput_pw(value);
      }else{
        // 何もしない
      }
  } 
    
  return (
      <ThemeProvider theme={theme}>
      <div class="titleheader"></div>
        <Container component="main" maxWidth="xs">  
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h6">
              こまめに勤怠
            </Typography>
            <Typography component="h1" variant="h4">
              まめれこ
            </Typography> */}
            <div class="titlelogo"></div>
            
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography style={{
              textAlign:'center',
              fontWeight:'bolder',
              fontSize:"1.5rem",
              color:"gray",
            }}>
              {`ver ${MAMERECO_VERSION}`}
            </Typography>
            
              <TextField
                margin="normal"
                required
                fullWidth
                id="user_id"
                label="user_id"
                name="user_id"
                autoComplete="user_id"
                value={input_user_id}
                autoFocus
                onChange={e => handleInputChage(e)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={input_pw}
                onChange={e => handleInputChage(e)}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="パスワードを保存する"
              /> */}


              {/* state User_Id と Pw を作成して submit で飛ばす */}
              <input hidden readOnly name="user_id" value={input_user_id}/>
              <input hidden readOnly name="pw" value={input_pw}/>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="signinButton"
              >
                Sign In
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} className="copyright"/>
        </Container>
      </ThemeProvider>
  );
}

export default SignIn;