
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import _ from 'lodash';

const styles = {
    grid: {
        // 列ヘッダに背景色を指定
        '.MuiDataGrid-columnHeaders': {
            backgroundColor: '#65b2c6', 
            color: 'snow',
        },
        // 行に背景色を指定
        '.MuiDataGrid-row': {
            backgroundColor: 'snow', 
            color: '#333333',
        }
    }
}

const JarnalViewGrid = (props) =>{

    const {columns, rows, setRows, keyName} = props;

    // セルの更新
    const changeCell = (v, rows, setRows) => {
        let newValue = _.cloneDeep(rows); /* lodashでstateをディープコピー */
        let idx = newValue.findIndex((d) => d[keyName] == v.id);  /* 該当データのindexを取得 */
        newValue[idx][v.field] = v.value;
        setRows(newValue);  /* 編集されたデータを置き換える */
    }

    return (
        !rows || !columns ? "" :
        <DataGrid
            onCellEditCommit={(e)=>changeCell(e, rows, setRows, keyName)}
            sx={styles.grid}  
            rows={rows}
            columns={columns} 
            getRowId={(rows) =>  rows[keyName]}
            HorizontalScrollBarVisibility="Visible"
            ScrollBarVisibility="Visible"
            density="compact"
            hideFooter
            autoHeight
            components={{
                Toolbar: GridToolbar,
            }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
    );
}

export default JarnalViewGrid;