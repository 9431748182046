
import GenericTemplate from "../templates/GenericTemplate";
import EnvMstEdit from "../conponents/EnvMstEdit";
import { useEffect, useState } from "react";
import ExportCSVMstEditList from "../conponents/ExportCSVMstEditList";
import { Box, Button, Typography } from "@mui/material";
import { Export_csv_mst } from "../classes/export_csv_mst_class";
import ExportCSVEditModal from "../conponents/ExportCSVEditModal";

const styles = {
    edit_wrapper:{
        display:"flex"
    },
    list_item_wrapper:{
        width:"40%",
        marginRight:"10px"
    },
    list_item_wrapper_view_mst:{
        width:"55%",
        marginRight:"10px"
    },
    list_item_container:{
        marginTop:"50px"
    },
    list_item_title:{
        textAligin:'center',
    },  
}


const ExportCSVMstPage = (props) =>{
    const {
        setLogin_user_id
        , setKintai_bscs
        , Login_user_info
        , setDisplay_user_info
        , Shift_msts
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [exportCsvBscItemMst, setExportCsvBscItemMst] = useState([]);
    useEffect(()=>{
        const fetchData = async () => {
            const res = await fetch('/api/select/export_csv_bsc_item_msts');
            const data = await res.json();
            setExportCsvBscItemMst(data.filter(p=>p.log_del_flg===false).sort((a, b) => a.seq - b.seq));
        }
        fetchData();
    },[])
    
    
    const [exportCsvMst, setExportCsvMst] = useState([]);
    useEffect(()=>{
        const fetchData = async () => {
            const res = await fetch('/api/select/export_csv_msts');
            const data = await res.json();
            setExportCsvMst(data.sort((a, b) => a.seq - b.seq));
        }
        fetchData();
    },[]);


    /**
     * CSV出力マスタに項目を追加削除する。
     * @param {*} item  
     * @param {*} shift_count_flg シフトカウント項目か判定フラグ
     */
    const shiftListDoubleClick = (item, shift_count_flg) =>{

        let targetColumnName = "";
        let targetName = "";
        if(shift_count_flg){
            targetColumnName="count_" + item.shift_type_cd;
            targetName = item.shift_name + "の回数"
        }else{
            targetColumnName=item.column_name;
            targetName = item.name
        }

        const targetItem = exportCsvMst.find(p=>p.column_name === targetColumnName);
        if(targetItem){
            // 項目がある場合削除
            removeItem(targetItem.column_name);

        }else{
            // 項目がない場合追加
            const mst = new Export_csv_mst();
            mst.column_name = targetColumnName;
            mst.name = targetName;
            mst.view_name = targetName;
            mst.export_name = targetName;
            mst.note = targetName;
            mst.shift_count_flg = shift_count_flg;
            mst.shift_type_cd = shift_count_flg?item.shift_type_cd:"";
            mst.seq = Math.max(...exportCsvMst.map(p=>p.seq))+1;
            
            setExportCsvMst(exportCsvMst=>[...exportCsvMst,mst]);

        }
        
    }

    /**
     * 項目削除
     */
    const removeItem = (column_name) => {
        setExportCsvMst(exportCsvMst => exportCsvMst.filter(p => p.column_name !== column_name));
    }


    
    // 配列の要素を入れ替える関数
    const swapArrayElements = (arr, index1, index2) => {
        const newArr = [...arr];
        const temp = newArr[index1];
        newArr[index1] = newArr[index2];
        newArr[index2] = temp;
        return newArr;
    };

    // // 配列の要素を入れ替えて状態を更新する関数
    const handleSwap = (index1, index2) => {
        setExportCsvMst(exportCsvMst => swapArrayElements(exportCsvMst, index1, index2));
        setSelectedIndex(index2);
    };


    /**
     * 保存ボタン
     */
    const handleConfirmButton = async () => {
        const tmpMst = updateSeqWithIndex(exportCsvMst);


        var url = "/api/update/export_csv_msts";
        const request ={
            method: 'POST',
            headers:{'Accept': 'application/json','Content-Type': 'application/json'},
            body: JSON.stringify({exportCsvMst: tmpMst})
        };
        await fetch(url,request);
    
        const res = await fetch(`/api/select/export_csv_msts`)
        const data = await res.json();
        setExportCsvMst(data.sort((a, b) => a.seq - b.seq));
        alert("データを更新しました。");
        
      }

    /**
     * 並び替え
     * @param {*} arr 
     * @returns 
     */
    const updateSeqWithIndex = (arr) => {
        return arr.map((item, index) => ({ ...item, seq: index }));
    };

    

    return (
        <GenericTemplate title="環境変数マスタ管理"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
            <Button className='acction_button' variant="contained" onClick={()=>handleConfirmButton()}>保存</Button>
            <Button className='acction_button' variant="contained" onClick={()=>handleSwap(selectedIndex, selectedIndex-1)}>up</Button>
            <Button className='acction_button' variant="contained" onClick={()=>handleSwap(selectedIndex, selectedIndex+1)}>down</Button>
            <Box sx={styles.edit_wrapper}>
                <Box sx={styles.list_item_wrapper}>
                    <Box sx={styles.list_item_container}>
                        <Typography variant={'h6'} sx={styles.list_item_title}>基本項目</Typography>
                        基本の項目です。
                        <ExportCSVMstEditList
                            LIST_NAME={'BSC_LIST'}
                            list={exportCsvBscItemMst}
                            height={"300px"}
                            name1={'name'}
                            name2={'note'}
                            shift_count_flg={false}
                            checkList={exportCsvMst}
                            handleOnDoubleClick={shiftListDoubleClick}
                            handleOnClick={null}
                            handleOnEditClick={null}
                            handleOnDelClick={null}
                        />
                    </Box>
                    <Box sx={styles.list_item_container}>
                        <Typography variant={'h6'} sx={styles.list_item_title}>シフトカウント項目</Typography>
                        シフトごとの件数をカウントします。
                        <ExportCSVMstEditList
                            LIST_NAME={'SHIFT_LIST'}
                            list={Shift_msts}
                            height={"300px"}
                            name1={'shift_name'}
                            name2={'shift_group_cd'}
                            shift_count_flg={true}
                            checkList={exportCsvMst}
                            handleOnDoubleClick={shiftListDoubleClick}
                            handleOnClick={null}
                            handleOnEditClick={null}
                            handleOnDelClick={null}
                        />
                    </Box>
                </Box>
                <Box sx={styles.list_item_wrapper_view_mst}>
                    <Box sx={styles.list_item_container}>
                        <Typography variant={'h6'} sx={styles.list_item_title}>表示項目</Typography>
                        　 項目　｜　表示名称　｜　出力名称
                        <ExportCSVMstEditList
                            LIST_NAME={'CSV_MST_LIST'}
                            list={exportCsvMst.sort()}
                            height={"700px"}
                            name1={'name'}
                            name2={'view_name'}
                            name3={'export_name'}
                            checkList={[]}
                            selectedIndex={selectedIndex}
                            handleOnDoubleClick={null}
                            handleOnClick={setSelectedIndex}
                            handleOnEditClick={setEditModalOpen}
                            handleOnDelClick={removeItem}
                        />
                    </Box>
                </Box>
            </Box>
            <ExportCSVEditModal
                selectedIndex={selectedIndex}
                exportCsvMst={exportCsvMst}
                setExportCsvMst={setExportCsvMst}
                editModalOpen={editModalOpen}
                setEditModalOpen={setEditModalOpen}
            ></ExportCSVEditModal>
        </GenericTemplate>
    );
}

export default ExportCSVMstPage;