export class Kintai_bsc {

    constructor(Kintai_bsc){
        this.user_id                            = Kintai_bsc?.user_id?Kintai_bsc.user_id:null;
        this.date                               = Kintai_bsc?.date?Kintai_bsc.date:null;
        this.shift_type_cd                      = Kintai_bsc?.shift_type_cd?Kintai_bsc.shift_type_cd:null;
        this.harf_holiday_shift_type_cd         = Kintai_bsc?.harf_holiday_shift_type_cd?Kintai_bsc.harf_holiday_shift_type_cd:null;
        this.paid_holiday_shift_type_cd         = Kintai_bsc?.paid_holiday_shift_type_cd?Kintai_bsc.paid_holiday_shift_type_cd:null;
        this.work_time_from                     = Kintai_bsc?.work_time_from?Kintai_bsc.work_time_from:null;
        this.work_time_to                       = Kintai_bsc?.work_time_to?Kintai_bsc.work_time_to:null;
        this.approval_status_cd                 = Kintai_bsc?.approval_status_cd?Kintai_bsc.approval_status_cd:null;
        this.work_time                          = Kintai_bsc?.work_time?Kintai_bsc.work_time:null;
        this.midnight_work_time                 = Kintai_bsc?.midnight_work_time?Kintai_bsc.midnight_work_time:null;
        this.rest_time                          = Kintai_bsc?.rest_time?Kintai_bsc.rest_time:null;
        this.over_time                          = Kintai_bsc?.over_time?Kintai_bsc.over_time:null;
        this.midnight_over_time                 = Kintai_bsc?.midnight_over_time?Kintai_bsc.midnight_over_time:null;
        this.behind_time                        = Kintai_bsc?.behind_time?Kintai_bsc.behind_time:null;
        this.early_time                         = Kintai_bsc?.early_time?Kintai_bsc.early_time:null;
        this.out_time                           = Kintai_bsc?.out_time?Kintai_bsc.out_time:null;
        this.training_time                      = Kintai_bsc?.training_time?Kintai_bsc.training_time:null;
        this.first_approval_userid              = Kintai_bsc?.first_approval_userid?Kintai_bsc.first_approval_userid:null;
        this.second_approval_userid             = Kintai_bsc?.second_approval_userid?Kintai_bsc.second_approval_userid:null;
        this.next_approval_userid               = Kintai_bsc?.next_approval_userid?Kintai_bsc.next_approval_userid:null;
        this.last_approval_userid               = Kintai_bsc?.last_approval_userid?Kintai_bsc.last_approval_userid:null;
        this.kintai_note                        = Kintai_bsc?.kintai_note?Kintai_bsc.kintai_note:null;
        this.approval_note                      = Kintai_bsc?.approval_note?Kintai_bsc.approval_note:null;
        this.public_holiday_time                = Kintai_bsc?.public_holiday_time?Kintai_bsc.public_holiday_time:null;
        this.paid_holiday_time                  = Kintai_bsc?.paid_holiday_time?Kintai_bsc.paid_holiday_time:null;
        this.count1                             = Kintai_bsc?.count1?Kintai_bsc.count1:null;
        this.count2                             = Kintai_bsc?.count2?Kintai_bsc.count2:null;
        this.count3                             = Kintai_bsc?.count3?Kintai_bsc.count3:null;
        this.count4                             = Kintai_bsc?.count4?Kintai_bsc.count4:null;
        this.count5                             = Kintai_bsc?.count5?Kintai_bsc.count5:null;
        this.log_del_flg                        = Kintai_bsc?.log_del_flg?Kintai_bsc.log_del_flg:false;
    }

    get getUser_id(){
        return this.user_id;
    }

    set setUser_id(user_id){
        this.user_id = user_id;
    }

}