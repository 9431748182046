import { Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import * as commonFnc from '../common/commonFnc';
import * as BL_kintai from '../BL/BL_kintai';
import TextField from "@material-ui/core/TextField";
import {GlobalContext} from '../globalState'
import SpKintaiSelectBox from "../conponents/SP_Kintai_SelectBox";
import * as BL_requestKintai from '../BL/BL_requestKintai_tmp';



const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    // primary: { main: colors.blue[800] }, // テーマの色
    primary: { main: colors.lightGreen[400] }, // テーマの色
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    title: {
      flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  })
);                                          



const SP_KintaiPage = (props) =>{
    const classes = useStyles();
    const {
      Kintai_bscs, 
      setKintai_bscs, 
      Shift_msts, 
      Department_msts, 
      Login_user_info, 
      // setLogin_user_info
    } = props;

    const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;
    const approvalTexts = useContext(GlobalContext).approvalTexts;

    // 打刻ジャーナル取得
    const [Clockin_time_from, setClockin_time_from] = useState();
    const [Clockin_time_to, setClockin_time_to] = useState();

    const [kintaiInfo, setKintaiInfo] = useState({});
    const [kintaiInfoPublicHolidayTime, setKintaiInfoPublicHolidayTime] = useState(0);
    const [kintaiInfoPaidHolidayTime, setKintaiInfoPaidHolidayTime] = useState(0);
    
    const [disabled_shiftType, setDisabled_shiftType] = useState(true);
    const [disabled_shiftType_harf, setDisabled_shiftType_harf] = useState(true);
    const [disabled_shiftType_paid, setDisabled_shiftType_paid] = useState(true);


    useEffect(()=>{
      
      setView(new Date)
      
    },[Kintai_bscs])
    

    const handleChangeDate = (e) => {
      const target = e.target;
      const value = target.value;

      setView(value)


    }

    const addDate = (tmp_kintaiInfo, num) =>{

      const tmpDate = new Date(tmp_kintaiInfo.date)
      tmpDate.setDate(tmpDate.getDate() + num)
      // setKintaiInfo({ ...tmp_kintaiInfo, date: tmpDate});

      const obj = document.getElementById("select_shift_type_cd");
      console.log(obj.selectedIndex)
      obj.selectedIndex = -1;

      setView(tmpDate);

    }

    const setView = (targetDate)=>{

      // 勤怠情報取得
      const Kintai_bsc = Kintai_bscs.find((p)=>commonFnc.formatDate(p.date) === commonFnc.formatDate(targetDate))      
      setKintaiInfo({
        date: Kintai_bsc ? commonFnc.formatDate(Kintai_bsc.date) : targetDate,
        shift_type_cd:Kintai_bsc ? Kintai_bsc.shift_type_cd : null,
        harf_holiday_shift_type_cd:Kintai_bsc&&Kintai_bsc?.harf_holiday_shift_type_cd ? Kintai_bsc.harf_holiday_shift_type_cd : null,
        paid_holiday_shift_type_cd:Kintai_bsc&&Kintai_bsc?.paid_holiday_shift_type_cd ? Kintai_bsc.paid_holiday_shift_type_cd : null,
        approval_status_cd:Kintai_bsc ? Kintai_bsc.approval_status_cd : "10",
        public_holiday_time:Kintai_bsc ? Kintai_bsc.public_holiday_time : 0,
        paid_holiday_time:Kintai_bsc ? Kintai_bsc.paid_holiday_time : 0,
        request_paid_holiday_reason:"",
      });

      // 打刻時間取得
      const query = new URLSearchParams({date: commonFnc.formatDate(targetDate), user_id: Login_user_info.user_id});
      fetch(`/api/select/clockin_jarnal?${query}`)
      .then((res) => res.json())
      .then((data) => {
          setClockin_time_from(data.sort((a,b)=>b.createdAt - a.createdAt).find((p)=>p.clockin_type_cd === "10"))
          setClockin_time_to(data.sort((a,b)=>b.createdAt - a.createdAt).find((p)=>p.clockin_type_cd === "90"))
      })
      
      setDisabled_shiftType((!Kintai_bsc || Kintai_bsc?.approval_status_cd ===  "10" )? false : true)
      
      // const tmp_shift_mst_harf = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc?.harf_holiday_shift_type_cd)
      setDisabled_shiftType_harf((!Kintai_bsc || Kintai_bsc?.approval_status_cd ===  "10") ? false : true);
      setDisabled_shiftType_paid((!Kintai_bsc || Kintai_bsc?.approval_status_cd ===  "10") ? false : true);

    }


    /**
     * 登録確定
     * @returns 
     */
    const updateKintaiInfo = () =>{

      var result = window.confirm('選択したシフト情報で更新しますか？');
      if( !result ) { return }
      
      const publicHolidayTime = BL_requestKintai.getHolidayTime(kintaiInfo.shift_type_cd, Shift_msts, "public_holiday_time") 
        + BL_requestKintai.getHolidayTime(kintaiInfo.harf_holiday_shift_type_cd, Shift_msts, "public_holiday_time") 
        + BL_requestKintai.getHolidayTime(kintaiInfo.paid_holiday_shift_type_cd, Shift_msts, "public_holiday_time");

      const paidHolidayTime = BL_requestKintai.getHolidayTime(kintaiInfo.shift_type_cd, Shift_msts, "paid_holiday_time") 
        + BL_requestKintai.getHolidayTime(kintaiInfo.harf_holiday_shift_type_cd, Shift_msts, "paid_holiday_time") 
        + BL_requestKintai.getHolidayTime(kintaiInfo.paid_holiday_shift_type_cd, Shift_msts, "paid_holiday_time");


      if(publicHolidayTime > 8){
        alert("8時間を超えた公休は取得出来ません。");
        return;
      }

      if(paidHolidayTime > 8){
        alert("8時間を超えた有給は取得出来ません。");
        return;
      }

      if(Login_user_info.enable_public_holiday_days < publicHolidayTime/8){
        alert("公休残数が足りません。");
        return;
      }

      if(Login_user_info.enable_paid_holiday_days < paidHolidayTime/8){
        alert("有給残数が足りません。");
        return;
      }


      const fmt_shiftDate = commonFnc.formatDate(kintaiInfo.date)
      
      var url = "/api/insert/kintai_bsc_sp"
      var bodyData = {
        user_id:Login_user_info.user_id,
        date:fmt_shiftDate,
        shift_type_cd: kintaiInfo.shift_type_cd,
        harf_holiday_shift_type_cd:kintaiInfo.harf_holiday_shift_type_cd,
        paid_holiday_shift_type_cd:kintaiInfo.paid_holiday_shift_type_cd,
        public_holiday_time:kintaiInfoPublicHolidayTime,
        paid_holiday_time:kintaiInfoPaidHolidayTime,
        enable_public_holiday_days:Login_user_info.enable_public_holiday_days,
        enable_paid_holiday_days:Login_user_info.enable_paid_holiday_days,
        request_paid_holiday_reason:""
      }
      const requestOptions ={
          method: 'POST',
          headers:{'Accept': 'application/json','Content-Type': 'application/json'},
          body: JSON.stringify(bodyData)
      };

      fetch(url,requestOptions)
        .then(()=>{
          const query = new URLSearchParams({login_user_id: Login_user_info.user_id});
          fetch(`/api/select/kintai_bsc?${query}`)
            .then((res) => res.json())
            .then((data) => {
              const Kintai_bsc = data.find((p)=>commonFnc.formatDate(p.date) === commonFnc.formatDate(kintaiInfo.date))   
              requestKintai(Kintai_bsc);
              // setKintai_bscs(data);
            })
            
        })
        .then(alert("シフト情報を更新しました。"));
    }


    const calcelKintaiInfo = ()=>{
      // 勤怠情報取得
      const Kintai_bsc = Kintai_bscs.find((p)=>commonFnc.formatDate(p.date) === commonFnc.formatDate(kintaiInfo.date))      
      setKintaiInfo({
        date: Kintai_bsc ? commonFnc.formatDate(Kintai_bsc.date) : kintaiInfo.date,
        shift_type_cd:Kintai_bsc ? Kintai_bsc.shift_type_cd : "",
        harf_holiday_shift_type_cd:Kintai_bsc ? Kintai_bsc.harf_holiday_shift_type_cd : "",
        paid_holiday_shift_type_cd:Kintai_bsc ? Kintai_bsc.paid_holiday_shift_type_cd : "",
        approval_status_cd:Kintai_bsc ? Kintai_bsc.approval_status_cd : "10",
        enable_public_holiday_days:0,
        enable_paid_holiday_days:0,
        request_paid_holiday_reason:""
      });

      cancelSelectBox();
    }

    
    const requestKintai = ((target_kintai_bsc) =>{
      const target_shift = Shift_msts.find((p)=>p.shift_type_cd === target_kintai_bsc?.shift_type_cd);
      if(target_kintai_bsc.approval_status_cd === "10" || target_kintai_bsc.approval_status_cd === "80"){
          if(!target_kintai_bsc.shift_type_cd || (target_shift?.shift_group_cd === shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"] && !target_kintai_bsc.harf_holiday_shift_type_cd)){
              alert("半休シフトが選択されていません。");
              return;
          }
          BL_kintai.requestApproval(target_kintai_bsc, Department_msts, setKintai_bscs);
      }
      
      if(target_kintai_bsc.approval_status_cd === "20"){
          BL_kintai.requestCancel(target_kintai_bsc, Department_msts, setKintai_bscs);
      }
    });

    // 未実装
    const cancelSelectBox = ()=>{

      // setKintaiInfo({
      //   ...kintaiInfo,
      //   shift_type_cd: null,
      //   harf_holiday_shift_type_cd: null,
      // })

      // const objShiftSelect = document.getElementById("select_shift_type_cd");
      // objShiftSelect.value = ""

      // console.log(objShiftSelect.value)

    }
    
    const shift_list = Shift_msts.sort((a, b) => a.seq < b.seq ? -1 : 1).filter((p)=>(p.shift_group_cd===shift_group_cd_hash["HOLIDAY_SHIFT_GROUP_CD"]));
    const harf_holiday_shift_list = Shift_msts.sort((a, b) => a.seq < b.seq ? -1 : 1).filter((p)=>(p.shift_group_cd===shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] && p.paid_holiday_time === 0 ));
    const paid_holiday_shift_list = Shift_msts.sort((a, b) => a.seq < b.seq ? -1 : 1).filter((p)=>(p.shift_group_cd===shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] && p.paid_holiday_time > 0));

    return (
            
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="absolute"
            >
        <Toolbar className={classes.toolbar}>
            <div class="clockin_toolbar">
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
            >
            </Typography>
            </div>
        </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            {!kintaiInfo?"":
            <form action="/api/update/clockin_jarnal" method="post" name="clockinForm" id="clockinForm">
                <div class="clockin_main_container">
                    <div class="clockin_container">
                        <div class="clockin_contents">
                          <TextField
                              label="日付"
                              variant="outlined"
                              name="date" 
                              value={commonFnc.formatDate(kintaiInfo.date)}
                              onChange={(e)=>handleChangeDate(e)}
                              className="clockin_select select_tworow"
                              type="date"
                          >
                          </TextField>
                          <div>
                            <Button className="acction_button sp_harf" variant="outlined" onClick={() => addDate(kintaiInfo,-1)}>◁</Button>
                            <Button className="acction_button sp_harf" variant="outlined" onClick={() => addDate(kintaiInfo,1)}>▷</Button>
                          </div>
                        </div>
                        <div>
                        </div>
                        <div class="clockin_contents">
                          <div class="shint_info_contents">
                                <div class="shift_info label">シフト　　：</div>
                                <div class="shift_info time">{kintaiInfo.shift_type_cd ? Shift_msts.find((p)=>p.shift_type_cd === kintaiInfo.shift_type_cd && p.log_del_flg===false)?.shift_name : "--"}</div>
                          </div>
                          <div class="shint_info_contents">
                                <div class="shift_info label">部分　休み：</div>
                                <div class="shift_info time">{kintaiInfo.harf_holiday_shift_type_cd ? Shift_msts.find((p)=>p.shift_type_cd === kintaiInfo.harf_holiday_shift_type_cd && p.log_del_flg===false)?.shift_name : "--"}</div>
                          </div>
                          <div class="shint_info_contents">
                                <div class="shift_info label">部分　有給：</div>
                                <div class="shift_info time">{kintaiInfo.paid_holiday_shift_type_cd ? Shift_msts.find((p)=>p.shift_type_cd === kintaiInfo.paid_holiday_shift_type_cd && p.log_del_flg===false)?.shift_name : "--"}</div>
                          </div>
                          <div class="shint_info_contents">
                                <div class="shift_info label">開始　打刻：</div>
                                <div class="shift_info time">{Clockin_time_from  ? Clockin_time_from.time : "--"}</div>
                          </div>
                          <div class="shint_info_contents">
                                <div class="shift_info label">終了　打刻：</div>
                                <div class="shift_info time">{Clockin_time_to ? Clockin_time_to.time :"--"}</div>
                          </div>
                          <div class="shint_info_contents">
                                <div class="shift_info label">申請　状況：</div>
                                <div class="shift_info time">{kintaiInfo.approval_status_cd ? approvalTexts[kintaiInfo.approval_status_cd] : "--"}</div>
                          </div>
                        </div>
                        <div class="clockin_contents">
                          <SpKintaiSelectBox
                            kintaiInfo={kintaiInfo}
                            setKintaiInfo={setKintaiInfo}
                            target_name="shift_type_cd"
                            label_name="シフト"
                            disabled_flg={disabled_shiftType}
                            Shift_msts={Shift_msts}
                            shift_list={shift_list}
                            // Login_user_info={Login_user_info}
                            // setLogin_user_info={setLogin_user_info}
                            setKintaiInfoPublicHolidayTime={setKintaiInfoPublicHolidayTime}
                            setKintaiInfoPaidHolidayTime={setKintaiInfoPaidHolidayTime}
                          ></SpKintaiSelectBox>
                          <SpKintaiSelectBox
                            kintaiInfo={kintaiInfo}
                            setKintaiInfo={setKintaiInfo}
                            target_name="harf_holiday_shift_type_cd"
                            label_name="部分休み"
                            disabled_flg={disabled_shiftType_harf}
                            Shift_msts={Shift_msts}
                            shift_list={harf_holiday_shift_list}
                            // Login_user_info={Login_user_info}
                            // setLogin_user_info={setLogin_user_info}
                            setKintaiInfoPublicHolidayTime={setKintaiInfoPublicHolidayTime}
                            setKintaiInfoPaidHolidayTime={setKintaiInfoPaidHolidayTime}
                          ></SpKintaiSelectBox>
                          <SpKintaiSelectBox
                            kintaiInfo={kintaiInfo}
                            setKintaiInfo={setKintaiInfo}
                            target_name="paid_holiday_shift_type_cd"
                            label_name="部分有給"
                            disabled_flg={disabled_shiftType_paid}
                            Shift_msts={Shift_msts}
                            shift_list={paid_holiday_shift_list}
                            // Login_user_info={Login_user_info}
                            // setLogin_user_info={setLogin_user_info}
                            setKintaiInfoPublicHolidayTime={setKintaiInfoPublicHolidayTime}
                            setKintaiInfoPaidHolidayTime={setKintaiInfoPaidHolidayTime}
                          ></SpKintaiSelectBox>
                        </div>
                        <div class="clockin_contents">
                          <Button className="clockin_type_button enter_botton" variant="outlined" onClick={()=>updateKintaiInfo()} >登録</Button>
                          <Button className="clockin_type_button outer_button" variant="outlined" onClick={()=>calcelKintaiInfo()} >取消</Button>
                        </div>
                    </div>
                </div>
            </form>
            }
        </Container>
        </main>
      </div>
    </ThemeProvider>
    );
}

export default SP_KintaiPage;