import * as commonFnc from '../common/commonFnc';

/**
 * お知らせ情報
 * @type {Information}
 */
export class Information {

    constructor(){
        
        this.information_id = "";
        this.title = "";
        this.contents = "";
        this.popup_flg = false;
        this.post_user_id = "";
        this.post_date = "";
        this.post_time = "";
        this.last_update_user_id = "";
        this.last_update_date = "";
        this.last_update_time = "";
        this.from_day = commonFnc.formatDate(new Date());
        this.to_day = commonFnc.formatDate(new Date());
        this.read_user_list = "99";
        this.send_to_group_list = "";
        this.send_to_user_id_list = "";
        this.release_flg = true;
        this.important_flg = false;
        this.log_del_flg = false;
    }

}