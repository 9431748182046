import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useEffect,useState } from "react";


// summary: メッセージダイアルログを表示する。
// param1 : String msg 表示文言
// param2 : noolean isOpen ダイアログ表示状態
// param3 : function doYes Yes押下時アクション
// return : function 


function CommonMsgDialog ({msg, isOpen, doYes}){

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(isOpen)
      }, [isOpen])
    
    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={()=>doYes()}
                aria-labelledby="common-dialog-title"
                aria-describedby="common-dialog-description"
                >
                <DialogContent>
                    {msg}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => doYes()} color="primary">
                    OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
export default CommonMsgDialog