
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Information } from '../classes/Information_class';
import * as BL_information from '../BL/BL_information';
import '../css/informationPage.css';

const InformationTable = (props) => {
  const {
    Information_list,
    infotype,
    isViewClosed,
    Users,
    edit_flg,
  } = props;


  return (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                  <TableCell align='center'>投稿日時</TableCell>
                  <TableCell>タイトル</TableCell>
                  <TableCell>内容</TableCell>
                  <TableCell align='center'>
                    {!edit_flg?"":
                      <Link to={{pathname: "/InformationPage/edit", state: {Information: new Information(), infotype:infotype}}}>新規投稿</Link>
                    }
                  </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {Information_list.filter(p=>isViewClosed || BL_information.isView(p)).map((Information)=>(
                <TableRow className={BL_information.isView(Information)?"":"information-table-row-closeed"}>
                  <TableCell className={'information-table-cell information-table-cell-post'} align='center'>
                    {Information.post_date + " " + Information.post_time}<br/>
                    {(Users.find(p=>p.user_id === Information.post_user_id))? ((Users.find(p=>p.user_id === Information.post_user_id))?.first_name + " " + (Users.find(p=>p.user_id === Information.post_user_id))?.second_name):"名無し"}
                  </TableCell>
                  <TableCell className={'information-table-cell information-table-cell-title'}>
                  {Information.important_flg?
                    <span class="important">!</span>
                  :""}
                    {Information.title}
                  </TableCell>
                  <TableCell className={'information-table-cell'}>{isViewClosed?(Information.contents).slice(0, (Information.contents).indexOf('\n')) + " ...":Information.contents}</TableCell>
                  <TableCell className={'information-table-cell'} align='center'>
                    {!edit_flg?"":
                      <Link to={{pathname: "/InformationPage/edit", state: {Information:Information, infotype:infotype}}}>編集</Link>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
        </Table>
    </TableContainer>
  );
}
export default InformationTable;