import {Button, Checkbox, FormControlLabel, Switch, TextField } from '@material-ui/core';
import React from 'react';
import '../css/Loading.css';
import { useHistory } from "react-router-dom";
import * as commonFnc from '../common/commonFnc';

const InformationEditForm = (props) => {

  const {
    Login_user_info,
    InformationView,
    setInformationView,
    setInformationFromEnterprise,
    setInformationFromSystem,
    infotype,
    Users,
  } = props;

  const history = useHistory();
  
  const handleInputChage = (e, Obj, setObj) =>{
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setObj({ ...Obj, [name]: value });
  } 

  const handleCheckedChange = (e, Obj, setObj) => {
      const target = e.target;
      const checked = target.checked;
      const name = target.name;
      setObj({ ...Obj, [name]: checked });
  };

  const handleSubmitButton = async (infotype) =>{

    var result = window.confirm('この情報でお知らせを登録しますか？');
    if( !result ) return;

    const timestamp = (commonFnc.formatTimestamp(new Date())).split(" ");


    //投稿ユーザを格納するかは全て投稿日が存在するかを基準にする。 
    const bodyData = {
      ...InformationView,
      post_user_id:InformationView.post_date?InformationView.post_user_id:Login_user_info.user_id,
      post_date:InformationView.post_date?InformationView.post_date:timestamp[0],
      post_time:InformationView.post_date?InformationView.post_time:timestamp[1],
      last_update_user_id:Login_user_info.user_id,
      last_update_date:timestamp[0],
      last_update_time:timestamp[1],
    };

    const url_kintai = `/api/update/${infotype}`;
    const requestOptions = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyData)
    };
    await fetch(url_kintai, requestOptions);

    
    const infoRes = await fetch("/api/select/information_from_enterprise")
    const infoData = await infoRes.json();
    setInformationFromEnterprise(infoData);

    const infoSysRes = await fetch("/api/select/information_from_system")
    const infoSysData = await infoSysRes.json();
    setInformationFromSystem(infoSysData);

    history.push('/InformationPage');

  }


  return (
    <div>
      
      {!InformationView?<div class="loader">Loading...</div>:
      <div>
        <div class="form_content">
          <h3>タイトル</h3>
          <TextField variant="standard" name="title" value={InformationView.title} onChange={(e)=>handleInputChage(e, InformationView, setInformationView)}/>
        </div>
        <div class="form_content">
          <h3>内容</h3>
          <TextField 
            variant="outlined" 
            multiline 
            minRows="6"
            className={"input-multiline"} 
            name="contents" 
            value={InformationView.contents} 
            onChange={(e)=>handleInputChage(e, InformationView, setInformationView)}
          />
        </div>
        <div class="form_content">
          <h3>掲載</h3>
          <TextField variant="standard" name="from_day" type="date" value={InformationView.from_day} onChange={(e)=>handleInputChage(e, InformationView, setInformationView)}/>
          　～　
          <TextField variant="standard" name="to_day" type="date" value={InformationView.to_day} onChange={(e)=>handleInputChage(e, InformationView, setInformationView)}/>
          <FormControlLabel
            style={{"marginLeft":"50px"}}
              control={<Checkbox name="important_flg" checked={InformationView.important_flg} onChange={(e)=>handleCheckedChange(e, InformationView, setInformationView)}/>}
              label={"重要"}
          />
          <FormControlLabel
            style={{"marginLeft":"30px"}}
            control={
              <Switch 
                name="release_flg" 
                checked={InformationView.release_flg} 
                onChange={()=>setInformationView({...InformationView, "release_flg":!InformationView.release_flg})} 
              />
            }
            label={InformationView.release_flg?"公開":"非公開"}
          />
        </div>
        <div class="form_content">
          <TextField 
            label="投稿日時" 
            value={InformationView.post_date + " " + InformationView.post_time}
            disabled
          />
          <TextField 
            label="投稿者" 
            value={(Users.find(p=>p.user_id === InformationView.post_user_id))?Users.find(p=>p.user_id === InformationView.post_user_id).first_name + " " + Users.find(p=>p.user_id === InformationView.post_user_id).second_name:"名無し"} 
            disabled
          />
          <TextField 
            label="最終更新日時" 
            value={InformationView.last_update_date + " " + InformationView.last_update_time} 
            disabled/>
          <TextField 
            label="最終更新者" 
            value={(Users.find(p=>p.user_id === InformationView.last_update_user_id))?Users.find(p=>p.user_id === InformationView.last_update_user_id).first_name + " " + Users.find(p=>p.user_id === InformationView.last_update_user_id).second_name:"名無し"} 
            disabled
          />
        </div>
        <Button variant='outlined' onClick={()=>handleSubmitButton(infotype)}>登録</Button>
      </div>
      }
  </div>
  );
}
export default InformationEditForm;