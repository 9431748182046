import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import React from "react";
import { useEffect,useState } from "react";


// summary: ID確認ダイアルログを表示する。
// param1 : String msg 表示文言
// param2 : noolean isOpen ダイアログ表示状態
// param3 : function doYes Yes押下時アクション
// param4 : function doNo No押下時アクション
// param5 : function setObj TextFieldに表示するオブジェクトのセッター
// param6 : function Obj TextFieldに表示するオブジェクト
// param7 : function columnName TextFieldに表示するオブジェクトのカラム名
// return : function 


function CommonIdCheckDialog ({msg, isOpen, doYes, doNo, setObj, Obj, columnName}){

    const [open, setOpen] = useState(false)


    const handleInputChage = (e) =>{

        var name = e.target.name;
        var value = e.target.value;

        setObj({
            ...Obj,
            [name]: value
        })
        
    }

    useEffect(() => {
        setOpen(isOpen)
      }, [isOpen])
    
    return (
        
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={()=>doNo()}
                aria-labelledby="common-dialog-title"
                aria-describedby="common-dialog-description"
                >
                <DialogContent>
                    <div>
                        {msg}
                    </div> 
                    <TextField  InputLabelProps={{ shrink: true }} label="ID" variant="outlined" name={columnName}
                        type="Number"
                        onChange={e => handleInputChage(e)}
                        value={Obj[columnName] ? Obj[columnName] : ""}
                        style = {{width: "400px"}} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => doNo()} color="primary">
                    キャンセル
                    </Button>
                    <Button onClick={() => doYes()} color="primary">
                    次へ
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
export default CommonIdCheckDialog