import { useContext } from "react";
import {GlobalContext} from '../globalState'
import _ from 'lodash';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DeleteIcon from '@material-ui/icons/Delete';


const ExportCSVMstEditList = (props) => {
  const {
    LIST_NAME
    , list
    , height
    , name1
    , name2
    , name3
    , shift_count_flg
    , checkList
    , selectedIndex
    , handleOnClick
    , handleOnDoubleClick
    , handleOnEditClick
    , handleOnDelClick
  } = props;
  
  const {shiftGroupInfo, setShiftGroupInfo} = useContext(GlobalContext);
    
  const styles = {
    list:{
      height:height,
      overflow: 'auto',
      margin:"10px",
      border: '1px solid gray'
    },
    item_column:{
      width:name3?'30%':'50%'
    }
  }

  const isExistItem = (item) =>{
    if(checkList.length === 0) return false;
    const targetColumnName = shift_count_flg ? "count_"+item.shift_type_cd: item?.column_name;
    
    return checkList.some(p => p?.column_name === targetColumnName);
  }

  const editButtonClick = (index) =>{
    handleOnClick(index) //setSelectedIndex
    handleOnEditClick(true);
  }


  return (
    <List sx={styles.list}>
      {list?.map((item, index)=>(
      <ListItem sx={{
        backgroundColor:isExistItem(item)?'lightgray':'snow', borderBottom:'snow 1px solid',
        borderLeft:selectedIndex===index?'#8dbb5a 5px solid':''
        }}>
        <ListItemButton 
          onClick={handleOnClick?()=>handleOnClick(index):null}
          onDoubleClick={handleOnDoubleClick?()=>handleOnDoubleClick(item , shift_count_flg):null}
          >
          <Box sx={styles.item_column}>
            {item[name1]}
          </Box>
          <Box sx={styles.item_column}>
            {name2? 
              LIST_NAME==='SHIFT_LIST'? shiftGroupInfo[item[name2]].name:
              item[name2]:""
            }
          </Box>
          <Box sx={styles.item_column}>
            {name3?item[name3]:""}
          </Box>
        </ListItemButton>
        {handleOnEditClick?
        <IconButton onClick={()=>editButtonClick(index)}>
          <BorderColorIcon />
        </IconButton>
        :<></>}
        {handleOnDelClick?
        <IconButton onClick={()=>handleOnDelClick(item.column_name)}>
          <DeleteIcon />
        </IconButton>:<></>}
      </ListItem>
      ))}
    </List>
  );
}
export default ExportCSVMstEditList;