
import GenericTemplate from "../templates/GenericTemplate";
import EnvMstEdit from "../conponents/EnvMstEdit";

const EnvMstPage = (props) =>{
    const {setLogin_user_id, setKintai_bscs, Login_user_info, setDisplay_user_info} = props;

    return (
        <GenericTemplate title="環境変数マスタ管理"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
            <EnvMstEdit/>
        </GenericTemplate>
    );
}

export default EnvMstPage;