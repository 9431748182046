/**
 * ユーザ操作ログ
 * @type {User_log}
 */

// 日付をYYYY-MM-DDの書式で返すメソッド
function formatDate(dt) {
    const y = dt.getFullYear();
    const m = ('00' + (dt.getMonth()+1)).slice(-2);
    const d = ('00' + dt.getDate()).slice(-2);
    return (y + '-' + m + '-' + d);
  }

// 「hhmmss」形式の時刻文字列に変換する関数
function formatTime(dt){
 
    const h = dt.getHours();
    const m = dt.getMinutes();
    const s = dt.getSeconds();
 
    const hh = ("00" + h).slice(-2);
    const mm = ("00" + m).slice(-2);
    const ss = ("00" + s).slice(-2);
 
    return (hh + ':' + mm + ':' + ss);
}

export class User_log {

    constructor(){
        
        this.date = formatDate(new Date());
        this.time = formatTime(new Date());
        this.log_level = "";
        this.user_id = "";
        this.source_name = "";
        this.page = "";
        this.process_name = "";
        this.process_type = "";
        this.summary = "";
        this.before = {};
        this.after = {};
        this.log_del_flg = false;

    }

}