import { useState } from "react";
import '../css/Loading.css';
import '../css/informationEditPage.css';
import GenericTemplate from "../templates/GenericTemplate";
import InformationEditForm from "../conponents/InformationEditForm";

import { useLocation } from "react-router-dom";

const InformationEditPage = (props) =>{
  const {
    setLogin_user_id,
    setKintai_bscs,
    Login_user_info,
    setDisplay_user_info,
    setInformationFromEnterprise,
    setInformationFromSystem,
    Users,
  } = props;

  
  const {state} = useLocation();  
  const [InformationView, setInformationView] = useState(state.Information);

    return (
      <GenericTemplate title="お知らせ編集"
          setLogin_user_id={setLogin_user_id}
          setKintai_bscs={setKintai_bscs}
          Login_user_info={Login_user_info}
          setDisplay_user_info={setDisplay_user_info}
      >
      {!InformationView?<div class="loader">Loading...</div>:
        <div>
          <InformationEditForm
            Login_user_info={Login_user_info}
            InformationView={InformationView}
            setInformationView={setInformationView}
            setInformationFromEnterprise={setInformationFromEnterprise}
            setInformationFromSystem={setInformationFromSystem}
            infotype={state.infotype}
            Users={Users}
          >
          </InformationEditForm>
        </div>
      }
      </GenericTemplate>
    );
}

export default InformationEditPage;